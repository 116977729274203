@import "../../../ui/settings";

.BusinessHours_Mirror {
  margin-bottom: 30px;
}

.BusinessHours {
  margin-bottom: 50px;

  & .header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .title {
      font-size: 16px;
      font-family: c("cdk.mainFontFamily");
    }

    & .description {
      font-family: c("cdk.mainFontFamily");
      font-size: c("cdk.ordinaryFontSize");
      font-weight: c("cdk.ordinaryFontWeight");
    }
  }

  & .body {
    margin-top: 74px;
    display: flex;
    justify-content: space-between;

    & .FormTextfield-input:disabled, .FormTextfield-input:read-only{
      color: black;
      border-bottom: none;
    }
  }

}

.SpecialBusinessHours {

  & .header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .title {
      font-size: 16px;
      font-family: c("cdk.mainFontFamily");
    }

    & .description {
      font-family: c("cdk.mainFontFamily");
      font-size: c("cdk.ordinaryFontSize");
      font-weight: c("cdk.ordinaryFontWeight");
    }
  }

  & .body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    & .FormTextfield-input:disabled, .FormTextfield-input:read-only{
      color: black;
      border-bottom: none;
    }
  }

  .btnContainer {
    display: flex;

    & input[type="radio"] {
      display: none;
    }
    
    & .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      padding: 8px;
      background-color: c("c.g10");
      margin-top: 20px;
      margin-right: 10px;
      color: white;
      font-size: 14px;
    }
    
    & .icon:hover {
      cursor: pointer;
      background-color: c("c.s90");
      color: white !important;
    }
    
    & .peer:checked ~ .icon {
      background-color: c("c.s90");
    }

    & .peer:not(:checked) ~ .icon {
      color: black;
    }
  }

  .SettingsTime-addDate {
    width: 100%;
    margin-top: 3rem;
    border: 1px dashed c("c.shadeF");
    border-radius: 0.8rem;
  }
  
  .SettingsTime-addDateButton {
    background-color: c("c.t");
    width: 100%;
    height: 100%;
    padding: 2rem 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  
    &:focus,
    &:hover {
      outline: none;
  
      .SettingsTime-addDateIcon {
        color: c("c.s90");
      }
    }
  }
  
  .SettingsTime-addDateIcon {
    color: c("c.s90");
    transition: color 0.2s;
  }
  
  .SettingsTime-addDateLabel {
    @include accent;
    font-weight: 400;
  }
  
  .SettingsTime-addDateIcon + .SettingsTime-addDateLabel {
    margin-left: 1.5rem;
  }

}


.AppointmentWorkingHours {
  margin-top: 40px;

  & .header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .title {
      font-size: 16px;
      font-family: c("cdk.mainFontFamily");
    }

    & .description {
      font-family: c("cdk.mainFontFamily");
      font-size: c("cdk.ordinaryFontSize");
      font-weight: c("cdk.ordinaryFontWeight");
    }
  }

  & .body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    & .FormTextfield-input:disabled, .FormTextfield-input:read-only{
      color: black;
      border-bottom: none;
    }
  }



}


.BusinessHoursDate {
  position: relative;
  width: 200px;
}

.BusinessHoursDate .rdrMonthAndYearWrapper {
  position: unset;
}

.BusinessHoursDate .rdrMonthAndYearPickers{
  display: block;
}

.BusinessHoursDate .DateRangePicker-dropdown{
  width: 260px;
  padding: 0 6px 4px 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 26px 40px #0000001A;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 100%;
  right: unset;
}

.BusinessHoursDate .DateRangePicker-toggler{
  align-items: center;
}
.BusinessHoursDate .DateRangePicker-togglerText{
  margin-top: 4px;
  color: #808080;;
}
.BusinessHoursDate .DateRangePicker-togglerIcon {
  order: -1;
  padding-right: 10px
}

.BusinessHoursDate.BusinessHoursDate--open {
  display: none;
}

.BusinessHoursDate-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.BusinessHoursDate-action--delete {
  background-color: c("cdk.iconBackgroundColor");
  color: c("cdk.iconColor");
  width: 3.2rem;
  height: 3.2rem;
  margin-left: 2rem;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    background-color: c("c.s75");
    color: c("c.white");
    outline: none;
  }
}

.SettingsTime-save{

  & .FormSubmit-button[disabled]{
    background-color: #b2b2b2;
  }
}

.switchFormControlLabel>span {
  font-size: 14px;
}
