@import "../../../ui/settings";

.SettingAccessSwitcher {
  @include smallest(rgba(c("c.p90"), 0.25));
  background-color: c("c.white");
  width: 8rem;
  height: 3rem;
  margin-right: 2rem;
  text-align: center;
  border: 1px solid c("c.shadeA");
  border-radius: 1rem;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &.SettingAccessSwitcher--on {
    .SettingAccessSwitcher-stateOn {
      background-color: c("c.s75");
      color: c("c.white");
    }
  }

  &:not(.SettingAccessSwitcher--on) {
    .SettingAccessSwitcher-stateOff {
      background-color: c("c.danger");
      color: c("c.white");
    }
  }
}

.SettingAccessSwitcher-stateOn {
  border-right: 1px solid c("c.shadeA");
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingAccessSwitcher-stateOff {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingAccessSwitcher {
    width: 10.5rem;
    height: 3.6rem;
  }
}
