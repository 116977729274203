@import "../../../ui/settings";

.TableCell {
  width: auto;
  padding: 1rem;
  border: 1px solid c("c.shadeA");

  .TableRow--disabled & {
    color: rgba(c("c.p90"), 0.25);
  }
}

.TableCell-formatNumber {
  @include smaller;
}

.TableCell-formatText {
  color: c("c.p70");
  font-size: 1.3rem;
  line-height: (20 / 15);

  .TableRow--disabled & {
    color: rgba(c("c.p70"), 0.25);
  }
}

.TableCell--important {
  color: c("c.p90");

  .TableRow--disabled & {
    color: rgba(c("c.p90"), 0.25);
  }
}

.TableCell--date {
  white-space: nowrap;
  font-weight: c("cdk.conversationsDateFontWeight");
}

@media screen and (min-width: c("bp.m")) {
  .TableCell {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .TableCell-formatText {
    font-size: 1.4rem;
  }

  .TableCell-formatTight {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .TableCell {
    padding: 2.5rem 3rem;
  }

  .TableCell-formatText {
    font-size: 1.5rem;
  }

  .TableCell-formatTight {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
