.CampaignSequences-Container {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  // margin: 5vh 0;

  // ul {
  //   columns: 2;
  //   -webkit-columns: 2;
  //   -moz-columns: 2;
  // }
}

.CampaignSequences-Loader {
  display: flex;
  justify-content: center;
}

.CampaignSequences-Error {
  display: flex;
  justify-content: center;
  font-size: 1.4vh;
}

.CampaignSequences-Label {
  display: flex;
  justify-content: center;
  margin-left: 6vh;
  margin-right: 6vh;
}
