@import "../../../ui/settings";

.ChatsListCard {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid c("c.shadeA");
}

.ChatsListCard:not(.is-active) {
  background-color: c("c.g01");
  color: c("c.g50");
  cursor: pointer;

  &:hover {
    background-color: c("c.g01");
  }
}

.ChatsListCard.is-active {
  background-color: c("cdk.conversationsTableHeaderColor");
}

.ChatsListCard-name {
  @include accent;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: (24 / 18);
}

.ChatsListCard-table {
  @include smallest;
  line-height: (16 / 11);

  display: grid;
  grid-template-columns: minmax(max-content, 9rem) auto;
}

.ChatsListCard-label {
  color: c("c.g50");
}

.ChatsListCard-value--channel {
  color: c("c.p90");
}

.ChatsListCard-value--contact {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none;
}

@media screen and (min-width: c("bp.l")) {
  .ChatsListCard {
    padding: 2rem 2.5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChatsListCard {
    padding: 2.6rem 3rem;
  }
}
