@import "../../../ui/settings";

.FormSubmit {
  display: flex;
  justify-content: flex-end;
}

.FormSubmit-button {
  background-color: c("c.s75");
  color: c("c.white");
  width: 100%;
  min-height: 4.4rem;
  padding: 0.5rem 3rem;
  font-size: 1.1rem;
  font-weight: c("cdk.buttonFontWeight");
  text-transform: c("cdk.generalTextTransform");
  text-align: center;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}
