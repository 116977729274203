@import "../../../../ui/settings";

.BusinessHoursDate {

  & .rdrDateRangePickerWrapper {
    width: 100%;
  }

  & .rdrDefinedRangesWrapper {
    width: 19.2rem;
    padding: 2rem;
    border-right: 1px solid c("c.shadeA");
    flex: 0 0 auto;
    display: none;
  }

  & .rdrDateRangeWrapper {
    background-color: c("c.t");
    width: 100%;
    // width: 65.8rem;
    padding: 2rem;
    position: relative;
  }

  & .rdrStaticRange {
    background-color: c("c.shadeA");
    min-height: 3.6rem;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: c("cdk.generalTextTransform");
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;

    &:focus,
    &:hover:not(.rdrStaticRangeSelected) {
      background-color: c("c.shadeB");
      outline: none;
    }
  }

  & .rdrStaticRangeSelected {
    background-color: c("c.s90"); 
    color: #fff;
  }


  & .rdrStaticRange:not(.rdrStaticRangeSelected) > .rdrStaticRangeLabel {
    color: c("c.p90");
    outline: none;
  }

  & .rdrStaticRange + .rdrStaticRange {
    margin-top: 1.3rem;
  }

  & .rdrInputRanges {
    display: none;
  }

  & .rdrDateDisplayWrapper {
    margin-bottom: 2rem;
  }

  & .rdrDateDisplay {
    flex-wrap: wrap;
  }

  & .rdrDateInput + & .rdrDateInput {
    margin-left: 0;
    margin-top: 1rem;
  }

  & .rdrDateInput {
    flex-basis: 100%;
    display: block;
    position: relative;

    &::after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23afb8ba'%3E%3Cpath d='M4.5 8.1h1.8v1.8H4.5zM8.1 8.1h1.8v1.8H8.1zM11.7 8.1h1.8v1.8h-1.8zM4.5 11.7h1.8v1.8H4.5zM8.1 11.7h1.8v1.8H8.1zM11.7 11.7h1.8v1.8h-1.8z'/%3E%3Cpath d='M17.1 1.8h-1.8V.9c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.9h-9V.9c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.9H.9c-.5 0-.9.4-.9.9v14.4c0 .5.4.9.9.9h16.2c.5 0 .9-.4.9-.9V2.7c0-.5-.5-.9-.9-.9zm-.9 14.4H1.8V5.4h14.4v10.8z'/%3E%3C/svg%3E");
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    input {
      @include input;
      text-align: center;
      color: black;
    }
  }

  & .rdrMonthAndYearWrapper {
    display: flex;
    justify-content: space-between;
  }

  & .rdrNextPrevButton {
    background-color: c("c.shadeA");
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' %3E%3Cpath fill='%231a1a1a' fill-rule='evenodd' d='M6.96 9.5L2.6 5.01 6.96.51l.45 1.53-3.06 2.97 3.06 2.96z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border: none;
    border-radius: 0.4rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: c("c.s90");
      color: #fff;
    }
  }

  & .rdrNextButton {
    transform: rotateZ(180deg);
  }

  & .rdrMonthAndYearPickers {
    display: flex;
    justify-content: center;
    align-items: center;

    & .rdrMonthPicker select{
      border: none;
      text-align: center;
      font-weight: normal;
      font-size: 20px;
      letter-spacing: 0.24px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    & .rdrMonthPicker select:focus-visible{ 
      outline: none;
    }

    & .rdrYearPicker {
      display: none;
    }
  }

  & .rdrMonths {
    flex-wrap: wrap;
  }

  & .rdrMonth {
    width: auto;
    flex: 1 1 100%;
  }

  & .rdrMonth + & .rdrMonth {
    margin-top: 2rem;
  }

  & .rdrMonthName {
    font-size: 1.5rem;
    text-align: center;
    line-height: (25 / 15);
  }

  & .rdrWeekDays {
    margin-top: 1.2rem;
  }

  & .rdrWeekDay {
    @include smallest(c("c.g25"));
    line-height: 2.8rem;
  }

  & .rdrDay {
    @include smallest;
    background-color: c("c.t");
    width: 3.4rem;
    width: (100% / 7);
    min-height: 2.6rem;
    padding: 0;
    border: none;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  & .rdrDayToday span{
    color: c("c.s90");
  }

  & .rdrDayDisabled span {
    color: rgba(c("c.g75"), 0.1);
  }

  & .rdrDayPassive span {
    color: rgba(c("c.g75"), 0.1);
  }

  & .rdrSelected,
  & .rdrStartEdge,
  & .rdrInRange,
  & .rdrEndEdge {
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 1px;
    right: 0;
  }

  & .rdrStartEdge,
  & .rdrEndEdge {
    background-color: c("c.s90");
    color: #fff;
  }

  & .rdrDay:has(.rdrSelected) .rdrDayNumber span,
  & .rdrDay:has(.rdrStartEdge) .rdrDayNumber span,
  & .rdrDay:has(.rdrEndEdge) .rdrDayNumber span {
    color: #fff;
  }

  & .rdrInRange {
    background-color: c("c.shadeA");
  }

  & .rdrDayHovered {
    background-color: rgba(c("c.g75"), 0.1);
  }

  & .rdrSelected {
    background-color: c("c.s90");
  }

}

@media screen and (min-width: c("bp.s")) {
  .rdrDateDisplay {
    flex-wrap: nowrap;
  }

  .rdrDateInput + .rdrDateInput {
    margin-top: 0;
    margin-left: 2rem;
  }

  .rdrMonths {
    flex-wrap: nowrap;
  }

  .rdrMonth + .rdrMonth {
    margin-top: 0;
    margin-left: 2rem;
  }
}

@media screen and (min-width: c("bp.m")) {
  .rdrDefinedRangesWrapper {
    display: block;
  }

}

@media screen and (min-width: c("bp.xxl")) {
  .rdrDefinedRangesWrapper {
    padding: 5rem 3rem;
  }

  .rdrDateRangeWrapper {
    padding: 4rem 6rem 5rem 6rem;
  }

  .rdrDateInput + .rdrDateInput {
    margin-left: 5rem;
  }

  .rdrDateDisplayWrapper {
    margin-bottom: 3rem;
  }

  .rdrMonth + .rdrMonth {
    margin-left: 5rem;
  }

  .rdrWeekDays {
    margin-top: 2.2rem;
  }
}
