@import "../../../ui/settings";

.TileSimple {
  background-color: c("c.white");
  min-width: 0;
  padding: 2.5rem 1.5rem;
  text-align: center;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);

  display: grid;
  grid-template-rows: auto min-content;
}

.TileSimple-label {
  @include smallest(c("cdk.smallTitleTextColor"));
  min-width: 0;
  margin-bottom: 1.2rem;
  word-break: break-word;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TileSimple-primary {
  @include larger;
  color: c("c.shadePurple");
  min-width: 0;

  align-self: center;
}

@media screen and (min-width: c("bp.xxl")) {
  .TileSimple {
    padding: 3rem 2.5rem 2.5rem;
  }
}
