@import "../../../ui/settings";

.ActionButtons {
  display: flex;
}

.ActionButtons-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
  color: c("cdk.actionButtonsColor");
}

.actionButtons-editButtonWrapper {
  display: flex;
  align-items: flex-end;
}