@import "../../ui/settings";

.Layout--pageConversation {
  .Header {
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

@media screen and (min-width: c("bp.m")) {
  .Layout--pageConversation {
    .Header {
      padding-right: 4rem;
      padding-left: 4rem;
    }
  }
}
