@import "../../../ui/settings";

.SettingsCMS-question {
  .SettingsCMSForm {
    padding-top: 3rem;
  }
}

.SettingsCMS-questionToggler {
  background-color: c("c.white");
  width: 100%;
  min-height: 8rem;
  padding: 1rem 20px;
  text-align: left;
  border: none;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.SettingsCMS-questionTogglerLabel {
  font-size: 16px;
  flex: 1 1 auto;
  
  p {
    position: relative;
    width: 80%;
  }

  .SettingsCMS-noAnswer{
    position: absolute;
    top: 15px;
    right: 20%;
  }
}

.SettingsCMS-questionTogglerIcon {
  flex: 0 0 auto;
}

.SettingsCMS-questionDeleteBtn{
  position: absolute;
  right: 45px;
  top: 30%;
  z-index: 10;
}

.SettingsCMS-questionIcon {
  color: hsla(0,0%,74.9%,.3);
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  flex: 0 0 7rem;
  display: flex;
  align-items: center;
}

.SettingsCMS-topics {
  margin-top: 4rem;
}

.SettingsCMS-topicsTitle{
  display: flex;
  justify-content: space-between;
}

.SettingsCMS-topicsHeading {
  @include accent;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: (20 / 18);
}

.SettingsCMS-questionsList {
  display: grid;
  gap: 3rem;
}

.SettingsCMS-questionItem {
  list-style: none;
  position: relative;
  z-index: 1;
}

.SettingsCMS-questionItem--processing {
  pointer-events: none;

  .SettingsCMSPerson-title {
    opacity: 0.5;
  }
}

.SettingsCMS-questionItem--selectable {
  cursor: pointer;
  transition: 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
    transition: 0.4s ease-in-out;
    box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  }

  &:active {
    box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.4);
  }
}

.SettingsCMS-addQuestion {
  width: 100%;
  height: 8rem;
  border: 1px dashed c("c.shadeF");
  border-radius: 0.8rem;
}

.SettingsCMS-addQuestionButton {
  background-color: c("c.t");
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;

    .SettingsCMS-addQuestionIcon {
      color: c("c.s90");
    }
  }
}

.SettingsCMS-addQuestionIcon {
  color: c("c.g10");
  transition: color 0.2s;
}

.SettingsCMS-addQuestionLabel {
  @include accent;
  font-weight: 400;
}

.SettingsCMS-addQuestionIcon + .SettingsCMS-addQuestionLabel {
  margin-left: 1.5rem;
}

.SettingsCMS-Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.SettingsCMS-ModalBlok {
  position: relative;
  width: 600px;
  background-color: white;
  border-radius: 10px;
  padding: 40px;

  p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  .SettingsCMS-ModalCloseBtn {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .Delete-button {
    background-color: c("c.s75");
    color: c("c.white");
    width: 100%;
    min-height: 4.4rem;
    padding: 0.5rem 3rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: c("cdk.generalTextTransform");
    text-align: center;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
  
    &:focus,
    &:hover {
      outline: none;
    }
  
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .Delete-cancel {
    background-color: c("c.white");
    color: c("c.s75");
    width: 100%;
    min-height: 4.4rem;
    padding: 0.5rem 3rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: c("cdk.generalTextTransform");
    text-align: center;
    border: 1px solid c("c.s75");
    border-radius: 0.8rem;
    cursor: pointer;
  
    &:focus,
    &:hover {
      outline: none;
    }
  
    &[disabled] {
      cursor: not-allowed;
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsCMS-question {
    .SettingsCMSForm-frame {
      border-top-right-radius: 0 !important;

      &::before {
        right: -1px !important;
      }

      &::after {
        content: none;
      }
    }
  }

/*   .SettingsCMS-questionToggler {
    padding-right: 4rem;
    padding-left: 4rem;
  } */

  .SettingsCMSForm {
    order: 1;
  }

  .SettingsCMSForm ~ .SettingsCMS-questionItem {
    order: 1;
  }

  .SettingsCMSForm
    + .SettingsCMS-questionItem:nth-child(2n - 1) {
    order: 0;
  }

  .SettingsCMS-questionsList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsCMS-questionTogglerLabel {
    font-size: 2rem;
  }

  .SettingsCMS-topics {
    margin-top: 5rem;
  }

  .SettingsCMS-topicsHeading {
    margin-bottom: 3rem;
  }
}
