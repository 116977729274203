@import "../../../ui/settings";

.ChartBar {
  width: 50%;
  height: 3rem;
  display: flex;
  align-items: center;
  position: relative;
}

.ChartBar-area {
  min-width: 2.5em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;

  &:first-child {
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }

  &:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
}

.ChartBar-area--negative {
  background-color: c("c.danger");
  color: c("c.white");
}

.ChartBar-area--neutral {
  background-color: c("c.g10");
}

.ChartBar-area--positive {
  background-color: c("c.s90");
  color: c("c.white");
}

@media screen and (min-width: c("bp.xxl")) {
  .ChartBar {
    height: 4.8rem;
  }
}
