@import "../../../ui/settings";

.TileSquare {
  background-color: c("c.white");
  min-width: 0;
  height: 0;
  padding-bottom: 100%;
  padding-bottom: #{"min(100%, 15rem)"};
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  position: relative;
}

.TileSquare-content {
  width: 100%;
  height: 100%;
  padding: 2.5rem 1.5rem;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  position: absolute;
  top: 0;
  left: 0;
}

.TileSquare-label {
  @include smallest(c("cdk.smallTitleTextColor"));
  word-break: break-word;

  align-self: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TileSquare-primary {
  @include larger;
  color: c("c.shadePurple");

  align-self: center;
}

.TileSquare-secondary {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  align-self: end;
}

.TileSquare--main {
  background-color: c("c.shadePurple");
  color: c("c.white");

  .TileSquare-label {
    color: c("c.white");
  }

  .TileSquare-primary {
    color: c("c.white");
  }
}

.TileSquare--clickable {
  transition: box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 3rem 7rem 0 rgba(c("c.black"), 0.15);
  }
}

@media screen and (min-width: c("bp.l")) {
  .TileSquare {
    padding-bottom: 100%;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .TileSquare-secondary {
    font-size: 2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .TileSquare-content {
    padding: 2.8rem 3rem;
  }

  .TileSquare-secondary {
    font-size: 2.4rem;
  }
}
