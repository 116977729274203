@import "../../ui/settings";

.User {
  height: 100%;
  overflow-y: auto;
}

.User-content {
  max-width: 108.6rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.User-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid c("c.shadeB");
}

.User-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
}

.User-form--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.User-formErrors {
  background-color: rgba(c("c.s75"), 1);
  color: c("c.white");
  margin-bottom: 3rem;
  padding: 1.5rem;
  font-size: 1.4rem;
}

.User-formField {
  & .FormTextfield-label {
    font-size: c("cdk.ordinaryFontSize");
  }
}

@media screen and (min-width: c("bp.s")) {
  .User-content {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .User-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .User-content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .User-heading {
    font-size: 2.8rem;
  }
}

.User__error {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
}
