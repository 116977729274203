@import "../../../ui/settings";

.FormChoicesLinkSubmit {
  display: flex;
  justify-content: flex-end;
}

.FormChoicesLinkSubmit-form {
  width: 100%;
}

.FormChoicesLinkSubmit-button {
  background-color: c("c.s75");
  color: c("c.white");
  width: 100%;
  min-height: 4.4rem;
  padding: 0.5rem 3rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: c("cdk.generalTextTransform");
  text-align: center;
  border: none;
  border-radius: 0.8rem 0 0 0.8rem;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.FormChoicesLinkSelect {
  width: 100%;
  position: relative;

  &.FormChoicesSelect--iconed {
    .FormChoicesSelect-toggler {
      padding-left: 3rem;
    }
  }
}

.FormChoicesSelect-label:not(.FormChoicesSelect-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.FormChoicesSelect-label--hidden {
  @include sr-only;
}

.FormChoicesSelect-field {
  display: flex;
  position: relative;
}

.FormChoicesSelect-fieldIcon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.FormChoicesSelect-toggler {
  @extend .FormChoicesLinkSubmit-button;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5rem;
  padding: 0;

  border-radius: 0 0.8rem 0.8rem 0;
  margin-left: 1px;

  &:focus,
  &:hover {
    outline: none;
  }
}

.FormChoicesSelect-toggler--disabled > .FormChoicesSelect-togglerText, .FormChoicesSelect-toggler--disabled > .FormChoicesSelect-togglerIcon {
  color: c("c.g50");
}

.FormChoicesSelect-togglerText {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 auto;
  overflow: hidden;
}

.FormChoicesSelect-list {
  background-color: c("c.white");
  width: 100%;
  max-height: 30rem;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  overflow-y: auto;
}

.WidgetAccount .FormChoicesSelect-list {
  overflow-x: hidden;
}

.FormChoicesSelect:not(.FormChoicesSelect--open) .FormChoicesSelect-list {
  display: none;
}

.FormChoicesSelect-listItem {
  list-style: none;
  min-height: 4.5rem;
  padding: 0.5rem 3rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: c("cdk.generalTextTransform");
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.FormChoicesSelect-listItems--active {
    color: c("c.s90");
  }

  &.FormChoicesSelect-listItems--disabled {
    color: c("c.g20");
    cursor: not-allowed;
  }
}

.FormChoicesSelect-listItem + .FormChoicesSelect-listItem {
  border-top: 1px solid c("c.shadeA");
}
