.ProcessedDataPreviewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .csv-data-container {
    display: flex;
    flex-direction: column;
    margin: 1vh 0;

    .csv-data {
      overflow: auto;
      min-width: 50vw;
      max-width: 50vw;
      max-height: 20vh;
    }

    .success {
      color: green;
    }
    .fail {
      color: red;
    }
    p {
      margin: 10px;
      text-decoration: underline;
    }
  }

  .csv-data-columns {
    display: flex;

    .csv-data-column {
      min-width: 70px;
      max-width: 70px;
      padding: 0 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #f9f9fa;
      color: #606367;
      border: 1px solid #c0c0c0;
    }
  }
}
