@import "../../../ui/settings";

.FormTextfield-label:not(.FormTextfield-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.FormTextfield-label--hidden {
  @include sr-only;
}

.FormTextfield-field {
  position: relative;
}

.FormTextfield-input {
  @include input;

  &:disabled,
  &:read-only {
    color: c("c.g50");
  }

  &:focus {
    outline: none;
  }
}

.FormTextfield-input.FormTextfield-input_error {
  border-color: #B72A3F;
}

.FormTextfield-input-outlined {
  @include input;

  outline: auto;
  outline-color: #ccc;
  padding: 1rem;
  background-color: #fff;

  &:disabled,
  &:read-only {
    color: c("c.g50");
  }
}

.FormTextfield-icon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  right: 0;
  // pointer-events: none;
  transform: translateY(-50%);
}

.FormTextfield-icon-component {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
}

.FormTextfield-error {
  font-size: 1vh;
}
