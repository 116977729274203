@import "../../../ui/settings";

.SettingsAnswersCard {
  display: grid;
}

.SettingsAnswersCard-current {
  background-color: c("c.white");
  border-radius: c("cdk.commonBorderRadius");
  box-shadow: c("cdk.settingsCardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.SettingsAnswersCard-currentQuestionWrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  border-bottom: 1px solid c("c.g10");
  display: flex;
  align-items: center;
}

.SettingsAnswersCard-currentQuestionIcon {
  color: rgba(c("c.g25"), 0.3);
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  flex: 0 0 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingsAnswersCard-currentQuestion {
  @include accent;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
}

.SettingsAnswersCard-currentAnswerWrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
}

.SettingsAnswersCard-currentAnswerIcon {
  color: rgba(c("c.g25"), 0.3);
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  flex: 0 0 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingsAnswersCard-currentAnswer {
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  word-break: break-word;
  flex: 1 1 auto;
}

.SettingsAnswersCard-togglerIcon {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SettingsAnswersCard-dropdown {
  background-color: c("c.shadeA");
  margin-top: -0.8rem;
  padding: 2.8rem 2rem 2rem;
  border: 1px solid c("c.shadeF");
  border-top: none;
  border-radius: 0 0 0.8rem 0.8rem;
}

.SettingsAnswersCard-formLabel {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 1.5rem;
  line-height: (20 / 11);
}

.SettingsAnswersCard-formList {
  list-style: none;
}

.SettingsAnswersCard-formListItem {
  display: flex;
  align-items: center;
}

.SettingsAnswersCard-formListItem + .SettingsAnswersCard-formListItem {
  margin-top: 2rem;
}

.SettingsAnswersCard-formOptionText {
  padding: 3rem;
  font-size: 1.6rem;
  font-weight: 500;
  border: 1px solid c("c.shadeF");
}

.SettingsAnswersCard-dropdown {
  .FormRadio-label {
    padding: 1.6rem 2rem;
    font-size: 1.5rem;
    line-height: 1.2;
    border: 1px solid c("c.shadeF");
  }

  .FormSubmit {
    margin-top: 2rem;
  }
}

.SettingsAnswersCard-form--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.SettingsAnswerCard-input {
  background-color: c("c.white");
  max-width: 30rem;
  text-align: center;
  border: none;
  border-radius: 0.2rem;

  &:focus {
    outline: none;
  }
}

.SettingsAnswerCard-textarea {
  background-color: c("c.t");
  width: 100%;
  min-height: 8rem;
  border: none;
  resize: vertical;

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsAnswersCard-formLabel {
    margin-bottom: 2.5rem;
  }

  .SettingsAnswersCard-currentQuestionWrapper {
    padding-right: 4rem;
  }

  .SettingsAnswersCard-currentAnswerWrapper {
    padding-right: 4rem;
  }

  .SettingsAnswersCard-currentAnswer {
    padding-right: 4rem;
    font-size: 1.6rem;
  }

  .SettingsAnswersCard-dropdown {
    padding-top: 3.8rem;
    padding-bottom: 3rem;
    padding-right: 4rem;
    padding-left: 4rem;

    .FormRadio-label {
      font-size: 1.6rem;
      flex: 1 1 auto;
    }

    .FormSubmit-button {
      width: auto;
    }
  }
}

@media screen and (min-width: c("bp.l")) {
  .SettingsAnswersCard-current {
    flex-direction: row;
  }

  .SettingsAnswersCard-currentQuestionWrapper {
    border-right: 1px solid c("c.g10");
    border-bottom: none;
    flex: 0 0 50%;
  }

  .SettingsAnswersCard-currentAnswerWrapper {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsAnswersCard-currentQuestionWrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .SettingsAnswersCard-currentQuestionIcon {
    font-size: 5.2rem;
    flex: 0 0 11rem;
  }

  .SettingsAnswersCard-currentQuestion {
    font-size: 2rem;
  }

  .SettingsAnswersCard-currentAnswerWrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .SettingsAnswersCard-currentAnswerIcon {
    font-size: 5.2rem;
    flex: 0 0 11rem;
  }

  .SettingsAnswersCard-currentAnswer {
    font-size: 1.6rem;
  }

  .SettingsAnswersCard-dropdown {
    padding-top: 4.3rem;
    padding-bottom: 4rem;

    .FormRadio-label {
      padding-top: 2.6rem;
      padding-bottom: 2.6rem;
      padding-right: 3rem;
      padding-left: 3rem;
      font-size: 1.5rem;
    }
  }
}
