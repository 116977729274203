@import "../../../ui/settings";

.Contact_layout {
  width: 100%;
  display: flex;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .Contact_form {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-top: 20px;
  }

  .CloseIconArea {
    position: relative;
    margin-top: 10px;
    .CloseIcon {
      position: absolute;
      bottom: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      right: 0;
      background-color: green;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .LoadingArea {
    height: 689px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ContactTitle {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  .ContactDescription {
    color: #666;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
  }
}
