@import "../../../ui/settings";

.SettingsDatePicker {
  width: 100%;

  &.SettingsDatePicker--error {
    .SettingsDatePicker-input {
      border-color: c("c.danger");
    }
  }

  &.SettingsDatePicker--disabled {
    .SettingsDatePicker-input {
      color: rgba(c("c.p90"), 0.25);
    }
  }
}

.SettingsDatePicker-label:not(.SettingsDatePicker-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.SettingsDatePicker-label--hidden {
  @include sr-only;
}

.SettingsDatePicker-input {
  @include input;

  &:focus {
    outline: none;
  }

  &[readonly] {
    color: rgba(c("c.p90"), 0.25);
  }

  &:not([readonly]) {
    &:focus,
    &:hover {
      border-color: c("c.shadeC");
    }
  }
}
