@import "../../ui/settings";

.Pagination {
  list-style: none;
  display: flex;
  align-items: center;
}

.Pagination-item + .Pagination-item {
  margin-left: 0.75rem;
}

@media screen and (min-width: c("bp.m")) {
  .Pagination-item + .Pagination-item {
    margin-left: 1rem;
  }
}
