@import "../../../ui/settings";

.FormCheckbox {
  user-select: none;
}

.FormCheckbox:not(.FormCheckbox--checked) {
  .FormCheckbox-label {
    color: rgba(c("c.p90"), 0.25);
  }
}

.FormCheckbox.FormCheckbox--focused {
  .FormCheckbox-fakeInput {
    border-color: c("c.shadeC");
  }
}

.FormCheckbox-field {
  display: flex;
  align-items: center;
  position: relative;
}

.FormCheckbox-input {
  @include sr-only;
}

.FormCheckbox-fakeInput {
  color: c("c.s75");
  width: 2.6rem;
  height: 2.6rem;

  border: 2px solid c("c.g10");
  border-radius: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.25s;
}

.FormCheckbox-label {
  color: c("c.p90");
  margin-left: 1.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  transition: color 0.2s;
}

@media screen and (min-width: c("bp.xxl")) {
  .FormCheckbox-fakeInput {
    width: 3.2rem;
    height: 3.2rem;
  }

  .FormCheckbox-label {
    margin-left: 2rem;
  }
}
