.StaticCampaigns-Container {
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.StaticCampaigns-Container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}
