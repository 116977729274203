@import "../../../ui/settings";

.ChatsDialog {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.ChatsDialog-container {
  padding: 2rem 6rem;
  flex: 1 1 auto;
  overflow-y: auto;
}

.ChatsDialog-footer {
  min-height: 6.6rem;
  padding: 1rem 6rem;
  border-top: 1px solid c("c.shadeA");
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.ChatsDialog-header {
  display: flex;
  justify-content: flex-end;

  .ChannelSelector_channel {
    background-color: c("c.g05");
    margin: 0.5rem;
    padding: 0.8rem;
    border-radius: 1rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;


    &.selected {
      background-color: white;
      border: 2px solid c("c.s90");

      &.ChannelSelector_Email {
        background-color: c("c.chatEmailBackground");
        border-color: c("c.chatEmailAuthorColor");
      }

      &.ChannelSelector_SMS {
        background-color: c("c.chatSMSBackground");
        border-color: c("c.chatSMSAuthorColor");
      }

      &.ChannelSelector_Whatsapp {
        background-color: c("c.chatWhatsAppBackground");
        border-color: c("c.chatWhatsAppAuthorColor");
      }
    }

    &:hover {
      opacity: 0.8;
      transition: 0.3s ease-in-out;
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .ChatsDialog-container {
    padding-left: 2rem;
  }

  .ChatsDialog-footer {
    min-height: 7rem;
    padding-left: 2rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .ChatsDialog-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 6rem;
  }

  .ChatsDialog-footer {
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChatsDialog-container {
    padding: 10rem 8rem;
  }

  .ChatsDialog-footer {
    padding-right: 8rem;
    padding-left: 8rem;
  }
}
