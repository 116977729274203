@import "../../../ui/settings";

.ChatsReply {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

@media screen and (min-width: c("bp.s")) {
  .ChatsReply {
    flex-wrap: nowrap;

    .FormElement--textarea {
      flex-shrink: 1;
    }

    .FormElement--submit {
      margin-top: 0;
      padding-left: 1.5rem;
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .ChatsReply {
    .FormTextfield-input {
      min-height: 4.4rem;
    }
  }
}

.ChatReply__editable-div {
  flex: 1;
  font-size: 13px;
  border: 1px solid #eee;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px 15px;
}
