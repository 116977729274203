@import "../../../ui/settings";

.FormGroup {
  margin-top: 3rem;
}

.FormGroup-title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid c("c.shadeB");
}

.FormGroup-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
}

@media screen and (min-width: c("bp.xxl")) {
  .FormGroup-title {
    font-size: 2.8rem;
  }
}
