@import "../../../ui/settings";

.DealerShip-content {
  max-width: 108.6rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.DealerShip-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid c("c.shadeB");
}

.DealerShip-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
}

.DealerShip-form--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.DealerShip-formErrors {
  background-color: rgba(c("c.s75"), 1);
  color: c("c.white");
  margin-bottom: 3rem;
  padding: 1.5rem;
  font-size: 1.4rem;
}

.DealerShip-formField{
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;

  & .FormSelect-label {
    font-size: c("cdk.ordinaryFontSize");
  }
}

@media screen and (min-width: c("bp.s")) {
  .DealerShip-content {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .DealerShip-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .DealerShip-content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .DealerShip-heading {
    font-size: 2.8rem;
  }
}

.DealerShip__error {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
}