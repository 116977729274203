
.Content {

}

.center {
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
