@import "../../../ui/settings";

.ChatsDialogMessage-Card_Box {
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px;
  padding-top: 20px;
}

.ChatsDialogMessage-Card_Container {
  flex: auto;
  flex-shrink: 0;
  max-width: 300px;

  &.ImageSection {
    width: 100%;
    border-radius: 16px 16px 0px 0px;

    & img {
      display: block;
      width: 100%;
      height: auto;
      max-height: 180px;
      border-radius: 16px 16px 0px 0px;
     }
  }

  &.TextSection {
    width: 100%;
    border-radius: 0px 0px 16px 16px;
    background-color: white;
    padding: 15px 0;

    & p {
      color: black;
      font-size: 11px;
      margin-left: 5px;
     }
  }

}

@media screen and (min-width: c("bp.l")) {

}

@media screen and (min-width: c("bp.xl")) {

}

@media screen and (min-width: c("bp.xxl")) {

}
