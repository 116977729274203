@import "../../ui/settings";

.UserManagementForm {
  padding: 2rem 5rem;
  overflow-y: scroll;
  height: 100%;
}

.UserManagementForm-title {
  margin: 2rem 0;
}

.UserManagementForm-subtitle {
  margin: 2rem 0;
}

.UserManagementForm-user {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 46px;
}

.UserManagementForm-userInputs {
  display: flex;
  max-width: 75%;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
}

.UserManagementForm-dataInputContainer {
  width: 21%;
}

.UserManagementForm-inputLabelWithTooltip {
  display: flex;
  align-items: flex-end;
}

.UserManagementForm-inputLabelWithTooltip > span {
  margin-right: 0.5rem;
}

.UserManagementForm-text {
  font-size: c("cdk.ordinaryFontSize");
  margin: 2rem 0;
}

.UserManagementForm-dealershipSearchContainer {
  width: 48%;
}

.UserManagementForm-addUserButton {
  background-color: c("c.t");
  width: 15%;
  height: 100%;
  padding: 2rem 0 0 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;

    .SettingsTime-addDateIcon {
      color: c("c.s90");
    }
  }
}

.UserManagementForm-addUserButtonIcon {
  color: c("c.g10");
  transition: color 0.2s;
}

.UserManagementForm-addUserButtonLabel {
  margin-left: 1rem;
  @include accent;
  font-weight: 400;
}

.UserManagementForm-removeUserButtonContainer {
  width: 10%;
}

.UserManagementForm-removeUserButton {
  display: flex;
  background-color: c("c.g10");
  color: c("c.g30");
  width: 3.2rem;
  height: 3.2rem;
  margin: auto;
  padding: 0;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    background-color: c("c.s75");
    color: c("c.white");
    outline: none;
  }
}

.UserManagementForm-removeUserButton_hidden {
  visibility: hidden;
}

.UserManagementForm-lowerButtons {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.UserManagementForm-lowerButtons > .FormElement {
  width: 20rem;
  margin: 0;
}

.UserManagementForm-cancelButton {
  color: c("cdk.buttonTextColor");
  background-color: c("cdk.userManagementCancelButtonFillColor");
  border: c("cdk.cancelButtonBorder");
  width: 100%;
  min-height: 4.4rem;
  padding: 0.5rem 3rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: c("cdk.generalTextTransform");
  text-align: center;
  border-radius: 0.8rem;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    background-color: c("cdk.userManagementCancelButtonHoverFillColor");
  }

  &[disabled] {
    cursor: not-allowed;
  }
}