@import "../../ui/settings";

.ProductSelector-label {
  width: 15vw;

  // &:hover ~ .ProductSelector-selector {
  //   display: block;
  // }
}

.ProductSelector-selector {  
  position: absolute;
  background-color: c("c.white");
  z-index: 10;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  width: 15vw;
  display: flex;
  flex-direction: column;
  font-size: 1.4vh;

  .ProductSelector-category {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    .ProductSelector-category-label {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #8eb7eb;
      width: 7vw;
      margin-bottom: 0.5vh;
    }

    .ProductSelector-product {
      width: 12vw;
      border-radius: 0.5rem;
      margin: 0.5vh 0;
      padding: 0.5vh 0;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
        transition: 0.3s ease-in-out;
      }
    }

    .ProductSelector-product-active {
      color: c("c.s90");;
    }
  }
  // &:hover ~ .ProductSelector-selector {
  //   display: block;
  // }
}


/* 
@import "ui/settings";

.ProductSelector-label {
  width: 15vw;

  // &:hover ~ .ProductSelector-selector {
  //   display: block;
  // }
}

.ProductSelector-selector {
  position: absolute;
  background-color: c("c.s30"); 
  z-index: 10;
  padding: 2vh 2vw;
  border-radius: 0.5rem;
  box-shadow: 0 3rem 7rem 0 rgba(c("c.white"), 0.15);
  color: white;
  width: 15vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.4vh;

  .ProductSelector-category {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ProductSelector-category-label {
      display: flex;
      flex-direction: column;
      text-align: center;
      border-bottom: 1px solid #8eb7eb;
      width: 7vw;
      margin-bottom: 0.5vh;
    }

    .ProductSelector-product {
      width: 12vw;
      background-color: #8eb7eb;
      text-align: center;
      border-radius: 0.5rem;
      margin: 0.5vh 0;
      padding: 0.5vh 0;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 0.8;
        transition: 0.3s ease-in-out;
      }
    }

    .ProductSelector-product-active {
      border: 1px solid white;
      background-color: #a8c6ee;
    }
  }
  // &:hover ~ .ProductSelector-selector {
  //   display: block;
  // }
}
*/
