@import "../../../ui/settings";
@import url("https://fonts.googleapis.com/css2?family=HK+Grotesk:wght@400;500;600;700&display=swap");

.DeleteDialog-close {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.DeleteDialog-text {
  color: c("c.g50");
  font-size: 1.4rem;
  margin: 1rem 0 3rem 0;
  font-weight: 400;
  font-family: "HK Grotesk", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

.DeleteDialog-nameInText {
  font-weight: bold;
  text-transform: capitalize;
}

.DeleteDialog-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.DeleteDialog-header > span {
  font-size: 18px;
  white-space: pre-line;
  font-weight: 700;
  font-family: "HK Grotesk", sans-serif;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.DeleteDialog-actions {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.DeleteDialog-actions .FormElement {
  width: 48%;
  margin: 0;
  font-size: 1.1rem !important;
  padding-bottom: 20px;
  text-transform: c("cdk.generalTextTransform");
}

.DeleteDialog-cancelButton {
  color: c("c.s75");
  background-color: c("c.white");
  border: 1px solid c("c.s75");
  width: 100%;
  min-height: 4.4rem;
  padding: 0.5rem 3rem;
  font-size: 1.1rem !important;
  font-weight: 700;
  text-transform: c("cdk.generalTextTransform");
  text-align: center;
  border-radius: 0.8rem;
  cursor: pointer;
  font-family: "HK Grotesk", sans-serif;

  &:focus,
  &:hover {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}
