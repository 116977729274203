.sourceColumn {
    font-weight: bold !important;
    color: var(--d80) !important;
    cursor: pointer;
}

.totalColumn {
    background-color: #F5F5F5;
}

.saleTableLoader{
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saleTableError{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .Loader {
    margin-left: 48%;
    margin-top: 8rem;
    color: #8777C6
} */

.ErrorImage {
    padding: 15px 0;
}

.ErrorMessage {
    color: #5A5A5A;
    font-size: 15px;
    padding-bottom: 15px 0;
}

.dashboardSaleNoData{
    position: sticky;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboardSaleNoData p {
    color: #5A5A5A;
    padding: 40px 0;
}
