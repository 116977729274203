@import "../../../ui/settings";

.ChatsListCollapsed {
  background-color: c("c.g01");
  width: 4rem;
  border-right: 1px solid c("c.shadeA");
  position: fixed;
  top: 7rem;
  bottom: 0;
  left: 0;
}

.ChatsListCollapsed-toggler {
  background-color: c("c.t");
  width: 100%;
  height: 100%;
  font-weight: 500;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: color 0.35s;

  &:focus,
  &:hover {
    color: c("c.s90");
    outline: none;
  }
}

.ChatsListCollapsed-togglerLabel {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
