:root {
  --s90: #196FE1;
  --s70: #196FE1;
  --s75: #196FE1;
  --s50: #196FE1;
  --d90: #9A9999;
  --d80: #196FE1;
  --d70: #446AA3;
  --generalTextTransform: uppercase;
  --mainFontFamily: "Open Sans", Arial, Helvetica, sans-serif;
  --chatEmailBackground: #f9ecdc;
  --chatSMSBackground: #dfe9f7;
  --chatWhatsAppBackground: #65D07233;
  --chatEmailAuthorColor: #e09f50;
  --chatSMSAuthorColor: #5d90d9;
  --chatWhatsAppAuthorColor: #478D4F;
  --cardLabelTextColor: #52557B;
  --cardLabelTextTransform: none;
  --progressBarTextSize: 14px;
  --dashboardTitleBottomMargin: 3rem;
  --danger: #bf3c30;
  --iconActive: #fff;
  --iconHover: #212230;
  --navBackground: #212230;
  --logoBackground: #f1f1f1;
  --subHeaderTextTransform: uppercase;
  --logoSize: 40%;
  --smallFontSize: 8px;
  --ordinaryFontSize: 12px;
  --ordinaryFontWeight: auto;
  --settingsSubHeaderFontSize: 1rem;
  --settingsSubHeaderBiggerFontSize: 1.1rem;
  --settingsSubHeaderBiggestFontSize: 1.2rem;
  --buttonFontWeight: 700;
  --smallTitleFontWeight: 700;
  --smallTitleDashboardFontWeight: normal;
  --smallTitleTextColor: #52557B;
  --dividerColor: #f1f1f1;
  --navDividerWidth: 0;
  --cardBoxShadow: 0px 0px 26px #00000026;
  --borderForBoxShadow: none;
  --commonBorderRadius: 10px;
  --cardHoverBoxShadow: 0px 2px 26px #00000026;
  --settingsCardBoxShadow: 0px 2px 26px #00000026;
  --buttonBorder: none;
  --sidebarMenuBackgroundColor: #414462;
  --sidebarMenuBackgroundHoverColor: #313349;
  --sidebarMenuTextDecoration: underline;
  --actionButtonsColor: #afb8ba;
  --buttonFillColor: #E8F1FC;
  --buttonHoverFillColor: #DCE9FA;
  --buttonTextColor: #155EBF;
  --cancelButtonBorder: none;
  --userManagementCancelButtonFillColor: #fff;
  --userManagementCancelButtonHoverFillColor: #fff;
  --iconBackgroundColor: #fff;
  --iconColor: #196FE1;
  --subSourcesLinkColor: #196FE1;
  --conversationsTableHeaderColor: #1a1a1a;
  --conversationsDateFontWeight: normal;
  --conversationsTableTitleFontWeight: normal;
  --conversationsTableTitleFontSize: 14px;
  --conversationsTableTitleTextTransform: lowercase;
  --conversationsButtonBorder: none;
  --conversationsTableEvenRowsBackgroundColor: #f1f1f1;
  --conversationsButtonFontSize: 14px;
  --conversationsButtonFontWeight: normal;
  --inputTextColor: inherit;
  --inputFontSize: 14px;
  --conversationsTakeoverInProgressStatusBackground: rgba(255, 0, 0, 0.07);
  --conversationsTakeoverInProgressStatusBorder: 0.5px solid #f00;
  --conversationsTakeoverInProgressStatusColor: #ff0000;
  --conversationsTakeoverBorderWidth: 0.5px;
  --conversationsTakeoverMaxHeight: 20px;
}

@function c($path) {
    $colors: (
      "iconActive": var(--iconActive),
      "iconHover": var(--iconHover),
      "navBackground": var(--navBackground),
      "logoBackground": var(--logoBackground),
      "white": #fff,
      "black": #000,
      "p90": #1a1a1a,
      "p70": #4d4d4d,
      "g01": #fcfcfc,
      "g05": #f1f1f1,
      "g10": #e6e6e6,
      "g15": #d9d9d9,
      "g20": #ccc,
      "g25": #bfbfbf,
      "g30": #b2b2b2, 
      "g50": #808080,
      "g65": #595959,
      "g70": #4c4c4c,
      "g75": #404040,
      "s90": var(--s90),
      "s70": var(--s70),
      "s75": var(--s75),
      "s50": var(--s50),
      "dashboardQuestionMark": var(--d90),
      "dashboardLinkLabel": var(--d80),
      "dashboardProgresbar": var(--d70),
      "s40": #4891f8,
      "s30": #414462,
      "shadeA": #f1f1f1,
      "shadeB": #deeaed,
      "shadeC": #afb8ba,
      "shadeD": #f1f1f1,
      "shadeE": #f7f9fa,
      "shadeF": #d3dbde,
      "positive": #00a651,
      "danger": var(--danger),
      "shadePurple": #8b76cc,
      "shadePurpleFade": #f4f0fc,
      "shadePurpleShadow": #ecebf2,
      "chatEmailBackground": var(--chatEmailBackground),
      "chatEmailAuthorColor": var(--chatEmailAuthorColor),
      "chatSMSBackground": var(--chatSMSBackground),
      "chatSMSAuthorColor": var(--chatSMSAuthorColor),
      "chatWhatsAppBackground": var(--chatWhatsAppBackground),
      "chatWhatsAppAuthorColor": var(--chatWhatsAppAuthorColor),
      "t": transparent,
    );

    $cdk: (
      "generalTextTransform": var(--generalTextTransform),
      "mainFontFamily": var(--mainFontFamily),
      "logoSize": var(--logoSize),
      "dashboardTitleBottomMargin": var(--dashboardTitleBottomMargin),
      "smallFontSize": var(--smallFontSize),
      "ordinaryFontSize": var(--ordinaryFontSize),
      "ordinaryFontWeight": var(--ordinaryFontWeight),
      "settingsSubHeaderFontSize": var(--settingsSubHeaderFontSize),
      "settingsSubHeaderBiggerFontSize": var(--settingsSubHeaderBiggerFontSize),
      "settingsSubHeaderBiggestFontSize": var(--settingsSubHeaderBiggestFontSize),
      "buttonFontWeight": var(--buttonFontWeight),
      "smallTitleFontWeight": var(--smallTitleFontWeight),
      "smallTitleDashboardFontWeight": var(--smallTitleDashboardFontWeight),
      "smallTitleTextColor": var(--smallTitleTextColor),
      "subHeaderTextTransform": var(--subHeaderTextTransform),
      "cardLabelTextColor": var(--cardLabelTextColor),
      "cardLabelTextTransform": var(--cardLabelTextTransform),
      "progressBarTextSize": var(--progressBarTextSize),
      "dividerColor": var(--dividerColor),
      "navDividerWidth": var(--navDividerWidth),
      "cardBoxShadow": var(--cardBoxShadow),
      "borderForBoxShadow": var(--borderForBoxShadow),
      "commonBorderRadius": var(--commonBorderRadius),
      "cardHoverBoxShadow": var(--cardHoverBoxShadow),
      "settingsCardBoxShadow": var(--settingsCardBoxShadow),
      "buttonBorder": var(--buttonBorder),
      "sidebarMenuBackgroundColor": var(--sidebarMenuBackgroundColor),
      "sidebarMenuBackgroundHoverColor": var(--sidebarMenuBackgroundHoverColor),
      "sidebarMenuTextDecoration": var(--sidebarMenuTextDecoration),
      "actionButtonsColor": var(--actionButtonsColor),
      "buttonFillColor": var(--buttonFillColor),
      "buttonHoverFillColor": var(--buttonHoverFillColor),
      "buttonTextColor": var(--buttonTextColor),
      "cancelButtonBorder": var(--cancelButtonBorder),
      "userManagementCancelButtonFillColor": var(--userManagementCancelButtonFillColor),
      "userManagementCancelButtonHoverFillColor": var(--userManagementCancelButtonHoverFillColor),
      "iconBackgroundColor": var(--iconBackgroundColor),
      "iconColor": var(--iconColor),
      "subSourcesLinkColor": var(--subSourcesLinkColor),
      "conversationsTableHeaderColor": var(--conversationsTableHeaderColor),
      "conversationsDateFontWeight": var(--conversationsDateFontWeight),
      "conversationsTableTitleFontWeight": var(--conversationsTableTitleFontWeight),
      "conversationsTableTitleFontSize": var(--conversationsTableTitleFontSize),
      "conversationsTableTitleTextTransform": var(--conversationsTableTitleTextTransform),
      "conversationsButtonBorder": var(--conversationsButtonBorder),
      "conversationsTableEvenRowsBackgroundColor": var(--conversationsTableEvenRowsBackgroundColor),
      "conversationsButtonFontSize": var(--conversationsButtonFontSize),
      "conversationsButtonFontWeight": var(--conversationsButtonFontWeight),
      "inputTextColor": var(--inputTextColor),
      "inputFontSize": var(--inputFontSize),
      "conversationsTakeoverInProgressStatusBackground": var(--conversationsTakeoverInProgressStatusBackground),
      "conversationsTakeoverInProgressStatusBorder": var(--conversationsTakeoverInProgressStatusBorder),
      "conversationsTakeoverInProgressStatusColor": var(--conversationsTakeoverInProgressStatusColor),
      "conversationsTakeoverBorderWidth": var(--conversationsTakeoverBorderWidth),
      "conversationsTakeoverMaxHeight": var(--conversationsTakeoverMaxHeight)
    );
  
    $config: (
      "c": $colors,
      "cdk": $cdk,
      "fontFaces": (
        1: (
          fileName: "HKGrotesk-Regular",
          name: "HKGrotesk",
          isGoogle: false,
          "weight": 400,
        ),
        2: (
          fileName: "HKGrotesk-Medium",
          name: "HKGrotesk",
          isGoogle: false,
          "weight": 500,
        ),
        3: (
          fileName: "HKGrotesk-Bold",
          name: "HKGrotesk",
          isGoogle: false,
          "weight": 700,
        ),
      ),
      "bp": (
        "xs": 375 / 16 * 1em,
        "s": 544 / 16 * 1em,
        "m": 768 / 16 * 1em,
        "l": 1280 / 16 * 1em,
        "xl": 1440 / 16 * 1em,
        "xxl": 1680 / 16 * 1em,
        "3xl": 1920 / 16 * 1em,
      ),
    );
  
    @return ns($config, $path);
  }
  