@import "../../ui/settings";

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Loader {
  width: 10rem;
  height: 10rem;
  position: relative;
}

.Loader-inner {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  box-shadow: 0 1px 0 0 c("c.s90");
  position: absolute;
  top: 15%;
  left: 15%;
  animation: loader 0.7s linear infinite;
}
