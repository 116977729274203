@import "../../../ui/settings";

.ChatsReply {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}

@media screen and (min-width: c("bp.s")) {
  .ChatsReply {
    flex-wrap: nowrap;

    .FormElement--textarea {
      flex-shrink: 1;
    }

    .FormElement--submit {
      margin-top: 0;
      padding-left: 1.5rem;
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .ChatsReply {
    .FormTextfield-input {
      min-height: 4.4rem;
    }
  }
}

.ChatReply__editable-div {
  flex: 1;
  font-size: 13px;
  border: 1px solid #eee;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px 15px;
}

.Chat-Form-Modal {
  display: none;
  @media screen and (max-width: c("bp.s")) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Actions {
  display: flex;
  flex-direction: column;
  align-items: center; /* Optional: Center align buttons horizontally */
  gap: 5px; /* Space between buttons */
}
.ShrinkArea {
  display: none;
  @media screen and (max-width: c("bp.s")) {
    background-color: #212230;
    width: 200px;
    height: 40px;
    position: absolute;
    right: 60px;
    bottom: 110px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  .ShrinkAreaTitle {
    display: flex;
    color: white;
    height: 100%;
    font-size: 12px;
    font-weight: 600;
    justify-items: center;
    text-align: center;
  }
  .ShrinkActions {
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 10px;
  }
}

.SendButton {
  width: 100%;
}

.EndConversation {
  width: 100%;

  .FormSubmit-button {
    background-color: black;
  }
}
