@import "../ui/settings";

@each $idx, $font in c("fontFaces") {
  @if not map-get($font, "isGoogle") {
    @font-face {
      $fileName: map-get($font, "fileName");
      font-family: map-get($font, "name");
      font-display: swap;
      src: local(#{$fileName}),
        url("../assets/fonts/#{$fileName}.woff2") format("woff2"),
        url("../assets/fonts/#{$fileName}.woff") format("woff");
      font-weight: map-get($font, "weight");
      font-style: normal;
    }
  }
}
