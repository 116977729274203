@import "../../../ui/settings";

.DealershipsTransferList {
  margin-top: 2.5rem;
}

.DealershipsTransferList-label-left {
  @include smallest(c("cdk.smallTitleTextColor"));
  padding-bottom: 0.4rem;
  border-bottom: 2px solid #deeaed;
  display: flex;
}

.DealershipsTransferList-label-right {
  @include smallest(c("cdk.smallTitleTextColor"));
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.4rem;
  border-bottom: 2px solid #deeaed;
}

.DealershipsTransferList-label-error {
  margin-left: 0.4rem;
  color: #B72A3F;
}

.DealershipsTransferList-clearAllButton {
  @include smallest(c("c.s75"));
  background-color: c("c.t");
  margin-left: 2rem;
  padding: 0;
  border: none;
  flex: 0 0 auto;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }
}

.DealershipsTransferList-listContainer {
  width: 48%;
}

.DealershipsTransferList-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: 26rem;
  overflow-y: scroll;
  border-bottom: 2px solid #deeaed;
}

.DealershipsTransferList-list_noBorder {
  border-bottom: none;
}

.DealershipsTransferList-listItem {
  padding-left: 0 !important;
  border-bottom: 2px solid #deeaed;
}

.DealershipsTransferList-listItem > button {
  background-color: transparent;
  border: none;
  margin-left: auto;
  cursor: pointer;
}

.DealershipsTransferList-listItem:last-child {
  border-bottom: none;
}

.DealershipsTransferList-listItem > div {
  display: flex;
  align-items: center;
  height: 2.6rem;
}

.DealershipsTransferList-listItem .FormCheckbox:not(.FormCheckbox--checked) .FormCheckbox-label {
    color: #1a1a1a;
}

.DealershipsTransferList-cdkIcon {
  height: 2.3rem;
  margin-right: 2px;
}

.DealershipsTransferList-cdkIcon_hidden {
  visibility: hidden;
}
