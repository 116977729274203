@import "../../ui/settings";

.Header {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  flex: 0 0 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Header-widget {
  margin-top: 2rem;
  max-width: 18.7vw;
  flex: 0 0 100%;
}

.Header-widget--account {
  flex: 1 1 0%;
}

@media screen and (min-width: c("bp.s")) {
/*   .Header {
    padding-right: 4rem;
    padding-left: 4rem;
  } */
}

@media screen and (min-width: c("bp.l")) {
  .Header {
    align-items: center;
  }

  .Header-widget {
    max-width: 18.7vw;
  }

  .Header-widget + .Header-widget {
    margin-left: 5rem;
  }

  .Header-widget--account {
    width: calc(18.7vw - 5rem);
    display: block;
  }
}

@media screen and (min-width: c("bp.xxl")) {
/*   .Header {
    padding-right: 10rem;
    padding-left: 10rem;
  } */

/*   .Header-widget--account {
    width: 25rem;
  } */
}
