.SequenceConfiguration-Container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .SequenceConfiguration-Container {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  .SequenceConfiguration-Loader {
    display: flex;
    justify-content: center;
  }

  .SequenceConfiguration-Error {
    display: flex;
    justify-content: center;
    font-size: 1.4vh;
  }

  .SequenceConfiguration-Block {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
  }

  .SequenceTemplateEditor-Title {
    font-size: 1.7vh;
    display: flex;
    justify-content: center;
  }
}
