@import "../../../../ui/settings";

.SettingsNotificationsPerson {
  background-color: c("c.white");
  min-height: 100%;
  border-radius: c("cdk.commonBorderRadius");
  display: flex;
  flex-direction: column;
  box-shadow: c("cdk.settingsCardBoxShadow");
  border: c("cdk.borderForBoxShadow");
}

.SettingsNotificationsPerson--selected {
  border: 1px solid c("c.s90");
}

.SettingsNotificationsPerson-heading {
  padding: 2rem;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}

.SettingsNotificationsPerson-title {
  @include accent;
  font-weight: 500;
  word-break: break-word;
  flex: 1 1 auto;
}

.SettingsNotificationsPerson-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.SettingsNotificationsPerson-action--edit {
  @include smallest(c("c.s75"));
  background-color: c("c.t");
  margin-left: 2rem;
  padding: 0;
  border: none;
  flex: 0 0 auto;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }
}

.SettingsNotificationsPerson-action--delete {
  background-color: c("cdk.iconBackgroundColor");
  color: c("cdk.iconColor");
  width: 3.2rem;
  height: 3.2rem;
  margin-left: 2rem;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    background-color: c("c.s75");
    color: c("c.white");
    outline: none;
  }
}

.SettingsNotificationsPerson-content {
  padding: 2rem;
}

@media screen and (min-width: c("bp.l")) {
  .SettingsNotificationsPerson-action--delete {
    margin-left: 3rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsNotificationsPerson-title {
    font-size: 2rem;
  }
}
