@import "../../ui/settings";

.DateRangePicker {
  width: 100%;
  position: relative;
}

.DateRangePicker-label {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.DateRangePicker-toggler {
  @include input;
  text-align: left;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:focus,
  &:hover {
    outline: none;
  }
}

.DateRangePicker-togglerText {
  @include accent;
  flex: 1 0 auto;
}

.DateRangePicker-dropdown {
  background-color: c("c.white");
  width: 100%;
  max-width: 85rem;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
}

.DateRangePicker:not(.DateRangePicker--open) .DateRangePicker-dropdown {
  display: none;
}

@media screen and (min-width: c("bp.s")) {
  .DateRangePicker-dropdown {
    width: calc(300% + 3rem);
  }
}

@media screen and (min-width: c("bp.l")) {
  .DateRangePicker-dropdown {
    width: 72rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .DateRangePicker-dropdown {
    width: 85rem;
  }
}
