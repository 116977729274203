@import "../../../ui/settings";

.ChatFormModalButtonsGroup-Area {
  width: 100%;
  display: flex;
  flex-direction: column;

  .HorizontaButtonsGroup-Area {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    .EachButton {
      flex: 1;
    }
  }
}
