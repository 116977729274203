@import "../../../ui/settings";

.FormNumfield-label:not(.FormNumfield-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.FormNumfield-label--hidden {
  @include sr-only;
}

.FormNumfield-field {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.FormNumfield-input {
  @include input;

  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.FormNumfield-input-error {
  border-color: #b72a3f;
}
.FormNumfield-error {
  font-size: 1vh;
}

.FormNumfield-icon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  right: 0;
  pointer-events: none;
  transform: translateY(-50%);
}
