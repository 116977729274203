.ConditionBlock-container {
  .ConditionBlock-title {
    font-size: 1.7vh;
  }
}

.Condition-container {
  display: flex;
  margin: 2vh;
  align-items: center;
  font-size: 1.5vh;

  .Condition-with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.ConditionComponent-container {
  display: flex;
  align-items: center;

  .ConditionComponent-input {
    margin: 0 0.5vw;
    width: 3vw;
    height: 2vh;

    input {
      outline: none;
    }
  }
}

.ConditionBlock-Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
