@import "../../ui/settings";

.breadcrumbs {
  font-size: c("cdk.ordinaryFontSize");
  font-weight: c("cdk.ordinaryFontWeight");

  & p, & li {
    font-size: c("cdk.ordinaryFontSize");
    font-weight: c("cdk.ordinaryFontWeight");
  }
}