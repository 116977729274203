@import "../../../ui/settings";

.calendarInputArea {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.calendar {
  position: relative;
  display: inline-flex;
  height: 34px;
  flex-direction: column-reverse;
  width: 100%;
  color: black;
}
.calendarInputTitle {
  color: c("cdk.smallTitleTextColor");
  font-size: c("cdk.settingsSubHeaderFontSize");
  font-weight: c("cdk.smallTitleFontWeight");
  text-transform: c("cdk.cardLabelTextTransform");
  font-style: normal;
  line-height: normal;
  margin-bottom: 10px;
}

.calendarToggle {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 7px 10px 11px 5px;
  cursor: pointer;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  border: none;
  border-bottom: 2px solid #deeaed;
  background-color: transparent;
}

.calendarToggle.active {
  border-bottom: 2px solid #afb8ba;
}

.calendarToggle.error {
  border-bottom: 2px solid c("cdk.danger");
}

.calendar.error {
  .calendarToggle {
    border: 1px solid c("cdk.danger");
  }
}

.calendarToggleContent {
  display: flex;
  justify-content: space-between;

  .calendarSelectedValue {
    min-width: 20px;
    width: fit-content;
    text-transform: capitalize;
  }
}

.calendarMenu {
  display: none;
  position: fixed;
  width: 265px;
  background-color: white;
  padding: 24px 11px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  box-shadow: c("cdk.cardBoxShadow");
  z-index: 2;
  translate: 0 -35px;
  border-radius: 5px;
}
.calendarMenu.open {
  display: flex;
}
.calendar.open {
  .calendarMenu {
    display: flex !important;
  }
}

.calendarTitle {
  color: var(--black, #000);
  font-size: 16px;
  font-weight: 700;
}

.calendarDaysCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.daysContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  button {
    border: none;
  }

  .numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .dayNumber {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: white;
      border: 1px solid c("cdk.conversationsTableHeaderColor");
      box-shadow: c("cdk.cardBoxShadow");
      cursor: pointer;
      font-size: 11.39px;
    }
  }

  .days {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .day {
      width: 30px;
      height: 18px;
      color: c("cdk.cardLabelTextColor");
      background-color: white;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
  }
}

.hoursContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px 6px;
  width: 100%;
  min-height: 188px;

  .hour {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: black;
    padding: 6px 10px;
    border-radius: 50px;
    padding: 5px 9px;
    outline: none;
    border: 1px solid var(--very-light-grey, #f0f0f0);
    background: white;
    box-shadow: c("cdk.cardBoxShadow");
    cursor: pointer;
  }
}

.hour.disabled,
.dayNumber.disabled {
  background: #f0f0f0;
  color: c("cdk.cardLabelTextColor");
  cursor: default !important;
}

.hour.selected {
  background-color: c("c.s90") !important;
  color: white !important;
}

.calendarBackground {
  display: none;
}

.dayNumber.selected {
  background-color: c("c.s90") !important;
  color: #ffffff !important;
}

.dayNumber.default {
  border: 1px solid c("c.s90") !important;
  color: c("c.s90") !important;
}
.calendarBackground {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  &.open {
    display: block;
  }
}

@media screen and (max-width: c("bp.s")) {
  .calendarMenu {
    position: fixed;
    width: unset;
    left: 10vw;
    right: 10vw;
    translate: unset;

    .hoursContainer {
      height: auto;
    }
  }
}

@media screen and (max-width: c("bp.s")) {
  .calendarMenu {
    gap: 10px;
    max-height: 80vh;
    position: fixed;
    top: 32vh;
    left: 15vw;
    right: 15vw;
  }
}
