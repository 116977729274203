@import "../../../ui/settings";

.ChatsDetailsCollapsed {
  background-color: c("c.g01");
  width: 4rem;
  border-left: 1px solid c("c.shadeA");
  position: fixed;
  top: 7rem;
  bottom: 0;
  right: 0;
}

.ChatsDetailsCollapsed-toggler {
  background-color: c("c.t");
  width: 100%;
  height: 100%;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: color 0.35s;

  &:focus,
  &:hover {
    color: c("c.s90");
    outline: none;
  }
}

.ChatsDetailsCollapsed-togglerLabel {
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotateZ(180deg);
}

@media screen and (min-width: c("bp.m")) {
  .ChatsDetailsCollapsed {
    top: 10.2rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .ChatsDetailsCollapsed {
    width: 8rem;
    position: static;
  }

  .ChatsDetailsCollapsed-toggler {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChatsDetailsCollapsed {
    width: 10rem;
  }

  .ChatsDetailsCollapsed-toggler {
    font-size: 2.9rem;
  }
}
