@import "../../../ui/settings";

$tilesGap: 1.8rem;

// Common
.DashboardCardArea {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.twinCardContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: c("bp.m")) {
  .twinCardContainer{
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: c("bp.l")) {
  .twinCardContainer{
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .twinCardContainer{
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .twinCardContainer{
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}