@import "../../../../../ui/settings";

.form{
    width: 75%;
    display: flex;
    flex-direction: column;
}

.elements {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 46px;

    & .form_isOpen{
        margin-right: 70px;
    }
}