@import "../../../ui/settings";

.DashboardSources {
}

.Sources-coming-soon {
  display: flex;
  margin: 3vh;
  font-size: 2vh;
  margin-left: 3vw;
}
