@import "../../../ui/settings";

.ChatsListCard {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid c("c.shadeA");
}

.ChatsListCard:not(.is-active) {
  background-color: c("c.g01");
  color: c("c.g50");
  cursor: pointer;

  &:hover {
    background-color: c("c.g01");
  }
}

.ChatsListCard.is-active {
  background-color: c("cdk.conversationsTableHeaderColor");
}

.ChatsListCard-name {
  @include accent;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: (24 / 18);
}

.ChatsListCard-table {
  @include smallest;
  line-height: (16 / 11);

  display: grid;
  grid-template-columns: minmax(max-content, 9rem) auto;
}

.ChatsListCard-label {
  color: c("c.g50");
  max-width: 8.5rem;
}

.ChatsListCard-value--channel {
  color: c("c.p90");
}

.ChatsListCard-value--contact {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none;
}

.ChatsListCard-value--takeover-status-complete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6rem;
  background: rgba(0, 56, 255, 0.07);
  border-radius: 10.5px;
  border: c("cdk.conversationsTakeoverBorderWidth") solid #0038FF;
  color: #0038FF;
  font-weight: 700;
  max-height: c("cdk.conversationsTakeoverMaxHeight");
  max-width: 100px;
}

.ChatsListCard-value--takeover-status-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6rem;
  background-color: c("cdk.conversationsTakeoverInProgressStatusBackground");
  border-radius: 10.5px;
  border: c("cdk.conversationsTakeoverInProgressStatusBorder");
  color: c("cdk.conversationsTakeoverInProgressStatusColor");
  font-weight: 700;
  max-height: c("cdk.conversationsTakeoverMaxHeight");
  max-width: 100px;
}

.ChatsListCard-value--progress {
  text-align: center;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: c("bp.l")) {
  .ChatsListCard {
    padding: 2rem 2.5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChatsListCard {
    padding: 2.6rem 3rem;
  }
}
