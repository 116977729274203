@import "../../../ui/settings";

.ChatsDialogMessage {
  max-width: 41rem;
  margin-bottom: 2.5rem;

  &.ChatsDialogMessage--right {
    margin-right: 0;
    margin-left: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ChatsDialogMessage-event {
  margin-top: 1rem;
  background-color: #fff !important;
  color: black !important;
  box-shadow: c("cdk.cardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  border-radius: c("cdk.commonBorderRadius");
}

.ChatDialogMessage-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

  .ChatDialogMessage-modal-content_DealerChat {
    display: flex;
    min-width: 40vw;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    outline: none;
    padding: 5vh;
    border-radius: 10px;

    .PayloadFormTitle{
      font-size: 20px;
      margin-bottom: 30px;
    }

    .PayloadForm {
      display: flex;

      .PayloadFormText{
        display: flex;
      }

      .PayloadFormKey {
        display: block;
        color: #808090;
        font-size: 12px;
        text-transform: c("cdk.generalTextTransform");
        width: 200px;
      }
    }

    & > p {
      font-size: 2rem;
    }
    .ChatDialogMessage-notification-list {
      display: flex;
      flex-direction: column;
      margin: 1vh;
      overflow: auto;

      .ChatDialogMessage-notification-list-item {
        margin: 1vh 0;

        .key {
          font-weight: 600;
        }
        p {
          margin: 1vh 1vw;
        }
      }
    }
  }
}

.ChatsDialogMessage-event-clickable {
  transition: 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: c("cdk.cardHoverBoxShadow");
    transition: 0.3s ease-in-out;
  }

  &:active {
    box-shadow: c("cdk.cardHoverBoxShadow");
    transition: 0.3s ease-in-out;
  }
}

.ChatsDialogMessage-data {
  display: flex;
  align-items: center;
}

.ChatsDialogMessage-icon {
  margin-top: 18px;
  margin-right: 20px;
  color: c("c.chatSMSAuthorColor");
  width: 1.8rem;
  flex: 0 0 auto;
}

.EventDetailsLabel {
  padding-bottom: 2vh;
}

.Margin {
  padding: 1vh;
}

.ChatsDialogMessage-text {
  background: rgba(135, 119, 198, 0.2) 0% 0% no-repeat padding-box;
  color: #0C0C0C;
  padding: 1rem 1.4rem;
  font-size: 1.1rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  word-break: break-word;
  border-radius: 1rem;
  flex: 1 1 auto;
}

.HorizontalLine {
  color: #e6e6e6;
  background-color: #e6e6e6;
  height: 0.2px;
  margin-top: 1vh;
  margin-bottom: 2vh;
  border-color: #e6e6e6;
}

.ChatsDialogMessage-meta {
  @include smallest(c("c.g30"));
  // padding: 1.6rem 0;
  line-height: (16 / 11);
  flex-shrink: 0;
}

.ChatsDialogMessage-spacer {
  flex-grow: 1;
  min-width: 10px;
}

.ChatsDialogMessage-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 0.6rem;

  .sms {
    color: c("c.chatSMSAuthorColor");
  }
  .email {
    color: c("c.chatEmailAuthorColor");
  }

  .ChatsDialogMessage-author {
    color: rgba(135, 119, 198, 1);
    text-transform: c("cdk.generalTextTransform");
    line-height: 1.4545454545;
    font-weight: 600;
    flex-shrink: 0;

    .bot-author {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.ChatsDialogMessage-author-wrapper {
  padding-right: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: max-content;
}

.ChatsDialogMessage--left {
  .ChatsDialogMessage-wrapper {
    margin-right: auto;
    align-items: flex-start;
    .ChatsDialogMessage-author-wrapper {
      margin-left: 1.5rem;
    }
  }

  .ChatsDialogMessage-text {
    color: c("c.black");
    margin-left: 1.5rem;

    &.sms {
      background-color: c("c.chatSMSBackground");
    }

    &.email {
      background-color: c("c.chatEmailBackground");
    }
  }

  .ChatsDialogMessage-meta {
    text-align: right;
  }
}

.ChatsDialogMessage--right {
  .ChatsDialogMessage-wrapper {
    margin-left: auto;
    align-items: flex-end;

    .ChatsDialogMessage-author {
      align-self: flex-start;
    }
  }

  .ChatsDialogMessage-icon {
    order: 1;
  }

  .ChatsDialogMessage-text {
    color: c("c.black");
    margin-right: 1.5rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .ChatsDialogMessage-icon {
    width: 2rem;
  }

  .ChatsDialogMessage-text {
    padding: 1.4rem 1.8rem;
    font-size: 1.2rem;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .ChatsDialogMessage.ChatsDialogMessage--left {
    .ChatsDialogMessage-author-wrapper,
    .ChatsDialogMessage-text {
      margin-left: 2rem;
    }
  }

  .ChatsDialogMessage.ChatsDialogMessage--right {
    .ChatsDialogMessage-text {
      margin-right: 2rem;
    }
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChatsDialogMessage-icon {
    width: 2.2rem;
  }

  .ChatsDialogMessage-text {
    padding: 1.6rem 2rem;
    font-size: 1.3rem;
  }
}
