.csv-data-rows {
  display: flex;

  .csv-data-row {
    min-width: 70px;
    max-width: 70px;
    padding: 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 0.5px solid #e2e3e3;
    background-color: #fffeff;
    color: #0c0c0d;
  }

  .csv-data-row-status {
    min-width: 200px;
    padding: 0 3px;
    border: 0.5px solid #e2e3e3;
    background-color: #fffeff;
    color: #0c0c0d;
  }
}
