@import "../../../../../../ui/settings";
.SettingsBlock-Container {
  margin: 4vh 0;
  display: flex;
  flex-direction: column;

  .SettingsBlock-Title {
    color: grey;
    font-size: 1.4vh;
    margin: 0 1vw;
  }

  .SettingsBlock-Cards-Container {
    margin-left: 3vw;
    font-size: 1.4vh;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    .SettingsBlock-Card {
      margin: 1vh;
      padding: 2rem;
      border-radius: 0.8rem;
      box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
      font-size: 1vh;
      display: flex;
      cursor: pointer;

      &:hover {
        box-shadow: 1rem 3rem 7rem 0 rgba(black, 0.15);
        transition: box-shadow 0.2s;
      }

      &.SettingsBlock-Card--add {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2vh;
      }

      &.SettingsBlock-Card--active {
        background-color: #1f67cc;

        p {
          color: #fff;
        }

        svg {
          fill: #fff;
          cursor: pointer;

          &:hover {
            fill: #ccc;
          }
        }
      }

      svg {
        fill: grey;
        cursor: pointer;

        &:hover {
          fill: #212121;
        }
      }

      .SettingsBlock-Title {
        color: grey;
        font-size: 1.4vh;
      }

      .SettingsBlock-Card-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.SettingsBlock-Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

@media screen and (max-width: c("bp.s")) {
  .SettingsBlock-Cards-Container {
    max-width: 80vw;
  }

  .SettingsBlock-Card {
    width: 60vw;

    svg {
      width: 4vw;
      height: 4vw;
    }
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsBlock-Cards-Container {
    max-width: 40vw;
  }

  .SettingsBlock-Card {
    width: 12vw;
    min-height: 7vw;

    svg {
      width: 0.8vw;
      height: 0.8vw;
    }
  }
}
