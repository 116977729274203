@import "../../../../ui/settings";

.AIRegistered{  
  &.cardContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: c("cdk.cardBoxShadow");
    border: c("cdk.borderForBoxShadow");
    border-radius: c("cdk.commonBorderRadius");
    opacity: 1;
    padding: 30px 30px;
    margin-bottom: 20px;
  }

  &.errorContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 26px #00000026;
    border-radius: 8px;
    opacity: 1;
    padding: 30px 30px;
    margin-bottom: 20px;

    .errorHeader{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .cardError{
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        letter-spacing: 0px;
        color: #5A5A5A;
        text-transform: c("cdk.generalTextTransform");

        a {
          margin-left: 10px;
        }
      }
    }

    .error {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .error p{
      padding: 15px 0;
      letter-spacing: 0.16px;
      font-size: 12px;
      color: #5A5A5A;
    }
  }

  .box1, .box2 {
    display: flex;
    flex-direction: column;
  }

  .cardLabel{
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: c("cdk.cardLabelTextColor");
    text-transform: c("cdk.cardLabelTextTransform");
  }

  .cardLabel a{
    margin-left: 10px;
  }

  .cardValue{
    display: flex;
  }

  .cardValue p:first-child{
    font-size: 40px;
    letter-spacing: 0px;
    padding: 10px 0 5px 0;
  }

  .cardValue p:first-child span{
    font-size: 40px;
    font-weight: bold;
  }
}

@media screen and (min-width: c("bp.m")) {
  .AIRegistered{  
    &.cardContainer{
      width: 48%;
    }

    &.errorContainer{
      width: 48%;
    }
  }

  .cardContainer{
    width: 48%;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .AIRegistered{  
    &.cardContainer{
      width: 48%;
    }

    &.errorContainer{
      width: 48%;
    }
  } 

  .cardContainer{
    width: 49%;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .AIRegistered{  
    &.cardContainer{
      width: 48%;
    }

    &.errorContainer{
      width: 48%;
    }
  }
  
  .cardContainer{
    width: 49%;
  }
}
