@import "../../../ui/settings";

.SettingsNotifications-add {
  .SettingsNotificationsForm {
    padding-top: 3rem;
  }
}

.SettingsNotifications-addToggler {
  background-color: c("c.white");
  width: 100%;
  height: 6rem;
  padding: 1rem 2rem;
  text-align: left;
  border: none;
  border-radius: c("cdk.commonBorderRadius");
  box-shadow: c("cdk.settingsCardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.SettingsNotifications-addTogglerLabel {
  font-size: 1.8rem;
  font-weight: 500;
  flex: 1 1 auto;
}

.SettingsNotifications-addTogglerIcon {
  flex: 0 0 auto;
}

.SettingsNotifications-persons {
  margin-top: 4rem;
}

.SettingsNotifications-personsHeading {
  @include accent;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: (20 / 18);
}

.SettingsNotifications-personsList {
  display: grid;
  gap: 3rem;
}

.SettingsNotifications-personsItem {
  list-style: none;
  position: relative;
  z-index: 1;
}

.SettingsNotifications-personsItem--processing {
  pointer-events: none;

  .SettingsNotificationsPerson-title {
    opacity: 0.5;
  }
}

.SettingsNotifications-personsItem--selectable {
  cursor: pointer;
  transition: 0.4s ease-in-out;

  &:hover {
    opacity: 0.7;
    transition: 0.4s ease-in-out;
    box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  }

  &:active {
    box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.4);
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsNotifications-add {
    .SettingsNotificationsForm-frame {
      border-top-right-radius: 0 !important;

      &::before {
        right: -1px !important;
      }

      &::after {
        content: none;
      }
    }
  }

  .SettingsNotifications-addToggler {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .SettingsNotificationsForm {
    order: 1;
  }

  .SettingsNotificationsForm ~ .SettingsNotifications-personsItem {
    order: 1;
  }

  .SettingsNotificationsForm
    + .SettingsNotifications-personsItem:nth-child(2n - 1) {
    order: 0;
  }

  .SettingsNotifications-personsList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: c("bp.xl")) {
  .SettingsNotifications-addToggler {
    height: 8rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsNotifications-addToggler {
    height: 10rem;
  }

  .SettingsNotifications-addTogglerLabel {
    font-size: 2rem;
  }

  .SettingsNotifications-persons {
    margin-top: 5rem;
  }

  .SettingsNotifications-personsHeading {
    margin-bottom: 3rem;
  }
}
