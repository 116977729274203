@import "../../../ui/settings";

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .Chat-Form-Modal-Desktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-list {
    display: flex;
    width: 100%;
  }

  .tab {
    padding: 10px 10px;
    flex: 1;
    cursor: pointer;
    // background-color: #f1f1f1;
    text-align: center;
    position: relative;
    font-size: 12px;
    font-style: normal;
    line-height: 20px;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &.active {
      border-top: 2px solid c("c.s90");
      color: c("c.s90");
      font-weight: 600;
      background-color: c("cdk.conversationsTableHeaderColor");
    }

    &.inactive {
      color: #667085;
      font-weight: 500;
    }

    .close-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: red;
    }
  }

  .tab-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto; /* If you want to add scroll for long content */
  }
}
