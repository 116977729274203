@import "../../../ui/settings";

.PaginationSeparator {
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.2rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: c("cdk.generalTextTransform");
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
}
