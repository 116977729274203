@import "../../../ui/settings";

$tilesGap: 1.8rem;

// Common
.TilesArea {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $tilesGap;
}

.TileDetails-frame {
  background-color: c("c.shadeA");

  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.TileDetails {
  &[data-number="1"] {
    .TileDetails-frame {
      &::before {
        content: "";
        background-color: c("c.shadeA");
        margin-left: -1px;
        border-left: 1px solid c("c.shadePurple");
        position: absolute;
        left: 0;
        right: 0;
        top: -2.6rem;
        bottom: 100%;
      }
    }
  }

  &[data-number="2"] {
    .TileDetails-frame {
      &::before {
        content: "";
        width: 5.2rem;
        height: 5.2rem;
        margin-left: -1px;
        border: 1px solid c("c.s90");
        border-radius: 50%;
        box-shadow: 0 0 0 2.6rem c("c.shadeA");
        position: absolute;
        bottom: 100%;
        left: 0;

        clip: rect(2.6rem 2.6rem auto auto);
      }
    }
  }
}

// Alpha
.TilesArea--alpha {
  .TileSquare {
    &[data-number="1"] {
      grid-area: 2 / 1;
    }
  }

  .TileDetails {
    &[data-number="1"] {
      .TileDetails-frame {
        border-left: 1px solid c("c.shadePurple");
      }
    }

    &[data-number="2"] {
      .TileDetails-frame {
        border-top: 1px solid c("c.shadePurple");
        border-right: 1px solid c("c.shadePurple");
        border-top-right-radius: 0.8rem;
        left: -1.8rem;
      }
    }

    &[data-number="3"] {
      .TileDetails-frame {
        border-bottom: 1px solid c("c.shadePurple");
        border-left: 1px solid c("c.shadePurple");
        border-bottom-left-radius: 0.8rem;
        top: -1.8rem;
        left: 0;
      }
    }

    &[data-number="4"] {
      .TileDetails-frame {
        border-right: 1px solid c("c.shadePurple");
        border-bottom: 1px solid c("c.shadePurple");
        border-bottom-right-radius: 0.8rem;
        top: -1.8rem;
        left: -1.8rem;
      }
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .TilesArea--alpha {
    grid-area: 1 / 1 / 2 / 5;
    grid-template-rows: max-content auto;
    grid-template-columns: repeat(4, 1fr);

    .TileSquare {
      &[data-number="1"] {
        grid-area: auto;
      }
    }

    .TileDetails {
      .TileDetails-frame {
        border-bottom: 1px solid c("c.shadePurple");
      }

      &[data-number="1"] {
        grid-area: 2 / 1;

        .TileDetails-frame {
          border-bottom-left-radius: 0.8rem;
        }
      }

      &[data-number="2"] {
        grid-area: 2 / 2;

        .TileDetails-frame {
          border-right: none;
          border-radius: 0;
        }
      }

      &[data-number="3"] {
        grid-area: 2 / 3;

        .TileDetails-frame {
          border-top: 1px solid c("c.shadePurple");
          border-left: none;
          border-radius: 0;
          top: 0;
          left: -1.8rem;
        }
      }
      &[data-number="4"] {
        grid-area: 2 / 4;

        .TileDetails-frame {
          border-top: 1px solid c("c.shadePurple");
          border-top-right-radius: 0.8rem;
          top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: c("bp.l")) {
  .TilesArea--alpha {
    grid-area: 1 / 1 / 2 / 5;
  }
}

// Beta
.TilesArea--beta {
  .TileDetails {
    &[data-number="1"] {
      grid-area: 2 / 1;

      .TileDetails-frame {
        border-bottom: 1px solid c("c.shadePurple");
        border-left: 1px solid c("c.shadePurple");
        border-bottom-left-radius: 0.8rem;
      }
    }

    &[data-number="2"] {
      grid-area: 2 / 2;

      .TileDetails-frame {
        border-top: 1px solid c("c.shadePurple");
        border-right: 1px solid c("c.shadePurple");
        border-bottom: 1px solid c("c.shadePurple");
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        left: -1.8rem;
      }
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .TilesArea--beta {
    grid-area: 2 / 1 / 4 / 3;
    grid-template-rows: max-content auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: c("bp.l")) {
  .TilesArea--beta {
    grid-area: 1 / 5 / 2 / 7;
    grid-template-columns: repeat(2, 1fr);
  }
}

// Gamma
.TilesArea--gamma {
  .TileDetails {
    text-align: center;

    &[data-number="1"] {
      &::after {
        content: none;
      }

      .TileDetails-frame {
        border-top: 1px solid c("c.shadeF");
        border-bottom: 1px solid c("c.shadeF");
        border-right: 1px solid c("c.shadeF");
        border-bottom-right-radius: 0.8rem;
        border-top-right-radius: 0.8rem;

        &::before {
          margin-top: -1px;
          margin-bottom: -1px;
          border-left: none;
          border-top: 1px solid c("c.shadeF");
          border-bottom: 1px solid c("c.shadeF");
          top: 0;
          bottom: 0;
          right: 100%;
          left: -2.6rem;
        }
      }
    }
  }

  .TileDetails-label {
    margin-bottom: 0.5rem;
  }

  .TileDetails-primary-lighter {
    font-size: 2.4rem;
    margin-left: 0.5vw;
  }

  .TileDetails-primary {
    @include larger;
  }
}

@media screen and (min-width: c("bp.m")) {
  .TilesArea--gamma {
    grid-area: 2 / 3 / 3 / 5;
  }
}

@media screen and (min-width: c("bp.l")) {
  .TilesArea--gamma {
    grid-area: 1 / 7 / 2 / 8;
    grid-template-rows: max-content auto;
    grid-template-columns: auto;

    .TileDetails {
      &[data-number="1"] {
        .TileDetails-frame {
          border-top: none;
          border-left: 1px solid c("c.shadeF");
          border-bottom-left-radius: 0.8rem;
          border-top-right-radius: 0;

          &::before {
            margin-bottom: 0;
            margin-top: 0;
            margin-right: -0.1rem;
            border-bottom: none;
            border-left: 1px solid c("c.shadeF");
            border-right: 1px solid c("c.shadeF");
            top: -2.6rem;
            bottom: 100%;
            right: 0;
            left: 0;
          }
        }
      }
    }
  }
}

// Delta
@media screen and (min-width: c("bp.m")) {
  .TilesArea--delta {
    grid-area: 3 / 3 / 4 / 5;
  }
}

@media screen and (min-width: c("bp.l")) {
  .TilesArea--delta {
    grid-area: 2 / 1 / 3 / 3;
  }
}
