@import "../../../ui/settings";

.SettingsFollowBlock--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SettingsFollowBlock + .SettingsFollowBlock {
  margin-top: 4rem;
}

.SettingsFollowBlock-label {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  font-weight: 500;
}

.SettingsFollowBlock-cards {
  display: grid;
  gap: 2rem;
}

.SettingsFollowCard {
  background-color: c("c.white");
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .FormCheckbox-label {
    margin-left: 1.2rem;
  }

  .FormCheckbox-label {
    @include smallest;
    color: c("c.g50");
  }

  .FormCheckbox:not(.FormCheckbox--checked) {
    .FormCheckbox-label {
      color: c("c.g50");
    }
  }
}

.SettingsFollowCard--stateOff {
  .SettingsFollowCard-periodValue {
    color: rgba(c("c.p90"), 0.25);
  }

  .SettingsFollowCard-periodLabel {
    color: rgba(c("c.p90"), 0.25);
  }
}

.SettingsFollowCard-period {
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
}

.SettingsFollowCard-periodValue {
  background-color: c("c.g10");
  min-width: 2.6rem;
  height: 2.6rem;
  font-size: 1.4rem;
  text-align: center;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.25s;
}

.SettingsFollowCard-periodLabel {
  margin-left: 1.2rem;
  transition: color 0.25s;
}

.SettingsFollowBlock-addCard {
  border: 1px dashed c("c.shadeF");
  border-radius: 0.8rem;
}

.SettingsFollowBlock-addCardButton {
  background-color: c("c.t");
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;

    .SettingsFollowBlock-addCardIcon {
      color: c("c.s90");
    }
  }
}

.SettingsFollowBlock-addCardIcon {
  color: c("c.g10");
  transition: color 0.2s;
}

.SettingsFollowBlock-addCardLabel {
  @include accent;
}

.SettingsFollowBlock-addCardIcon + .SettingsFollowBlock-addCardLabel {
  margin-left: 1.5rem;
}

@media screen and (min-width: c("bp.s")) {
  .SettingsFollowBlock-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: c("bp.l")) {
  .SettingsFollowBlock-cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsFollowBlock-label {
    font-size: 1.8rem;
  }

  .SettingsFollowBlock-cards {
    gap: 3rem;
  }

  .SettingsFollowCard {
    padding: 3rem;

    .FormCheckbox-fakeInput {
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .SettingsFollowCard-periodValue {
    width: 2.8rem;
    height: 2.8rem;
    font-size: 1.5rem;
  }
}
