@import "../../../ui/settings";

.TableHead-cell {
  //@include smallest;
  background-color: c("cdk.conversationsTableHeaderColor");
  color: c("c.s50");
  padding: 1rem 2.5rem 1rem 1rem;
  text-align: left;
  letter-spacing: 0.025em;
  border: 1px solid c("c.shadeA");
  z-index: 1;
  font-size: c("cdk.conversationsTableTitleFontSize");
  font-weight: c("cdk.conversationsTableTitleFontWeight");
  text-transform: c("cdk.conversationsTableTitleTextTransform");
}

.TableHead-cell:first-letter {
  text-transform: uppercase;
}

.TableHead-cell--sortable {
  position: relative;
  cursor: pointer;
}

.TableHead-cell--sticky {
  background-color: c("cdk.conversationsTableHeaderColor");
  border: none;
  position: sticky;
  top: 0;
}

.TableHead-cell--sticky-no-color {
  border: none;
  position: sticky;
  top: 0;
}

.TableHead-cell--center {
  padding-left: 2.5rem;
  text-align: center;
}

.TableHead-sort {
  color: c("c.s50");
  width: 2rem;
  height: 5rem;
  margin-top: -2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0.5rem;

  &.TableHead-sort--asc {
    transform: rotateZ(180deg);
  }
}

@media screen and (min-width: c("bp.m")) {
  .TableHead-cell {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .TableHead-cell {
    padding: 1.8rem 4rem 1.8rem 3rem;
  }

  .TableHead-cell--center {
    padding-left: 4rem;
  }

  .TableHead-sort {
    right: 2rem;
  }
}
