@import "../../ui/settings";


.TableContainer {
  overflow-x: auto;
}

.TableContainerScrollable {
  // overflow-x: auto;
  overflow: auto;
  max-height: 40vh;
}

.TableContainerScrollable::-webkit-scrollbar {
  width: 18px;
}

/* Track */
.TableContainerScrollable::-webkit-scrollbar-track:hover {
  background: #f1f1f1; 
}
 
/* Handle */
.TableContainerScrollable::-webkit-scrollbar-thumb {
  background-color: #212230;
  background-clip: content-box;
  height: 65px;
}

/* Handle on hover */
.TableContainerScrollable::-webkit-scrollbar-button {
  background: #555; 
}

.TableContainer--fillParent {
  overflow-y: auto;

  .TableContainer-table {
    overflow: visible;
  }

  .TableRow--first {
    .TableCell {
      border-top: none;
    }
  }

  .TableRow--last {
    .TableCell {
      border-bottom: none;
    }
  }

  .TableCell--first {
    border-left: 0;
  }

  .TableCell--last {
    border-right: 0;
  }
}

.TableContainer:not(.TableContainer--fillParent) {
  .TableContainer-table {
    border-radius: 0.8rem 0.8rem 0 0;
    overflow: hidden;
  }
}

.Table {
  width: 100%;
  border-collapse: collapse;
}

.Table--layoutFixed {
  table-layout: fixed;
}

.Table-expandButton {
  @include smallest(c("c.s90"));
  background-color: c("c.white");
  min-height: 4rem;
  width: 100%;
  text-align: center;
  border-top: none;
  border-bottom: 1px solid c("c.shadeA");
  border-right: 1px solid c("c.shadeA");
  border-left: 1px solid c("c.shadeA");
  border-radius: 0 0 0.8rem 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:focus,
  &:hover {
    background-color: c("c.shadeA");
    outline: none;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .Table-expandButton {
    min-height: 5.2rem;
  }
}
