@import "../../../../../../../ui/settings";

@media screen and (max-width: c("bp.s")) {
  .SettingsModal-Container {
    background-color: #fff;
    padding: 4vh;
    overflow-x: auto;
    overflow-y: auto;
    height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .SettingsModal-Actions {
    display: flex;
    justify-content: center;
    margin: 5vh 0;
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsModal-Container {
    background-color: #fff;
    padding: 10vh;
    border-radius: 1vh;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 90vh;
    min-width: 80vw;
    max-width: 80vw;
  }

  .SettingsModal-Actions {
    display: flex;
    justify-content: flex-end;
    margin: 10vh 0 0 0;
  }
}
