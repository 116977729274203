.SelectableCard-Container {
  background-color: white;
  border-radius: 0.8rem;
  margin-bottom: 2vh;
  min-width: 30rem;
  height: 12rem;
  box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
  margin: 1vh;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: ease-in-out 0.3s;
  position: relative;
  max-width: 40rem;

  .SelectableCard-meta {
    font-size: 1.3vh;
    font-weight: normal;
  }

  .SelectableCard-check {
    margin: 1rem 1.5vw;
    font-size: 1.7rem;
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }

  .SelectableCard-count {
    position: absolute;
    top: -1rem;
    right: -1rem;
    background-color: rgb(28, 184, 100);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vh;
    color: #fff;
  }

  &:hover {
    box-shadow: 1rem 3rem 7rem 0 rgba(black, 0.15);
    transition: ease-in-out 0.3s;
  }

  .SelectableCard-Container-p {
    margin: 1.5rem 1.5vw 0 1.5vw;
  }

  &.SelectableCard-Container--active {
    background-color: #1f67cc;
    box-shadow: 0 2rem 5rem 0 rgba(#1f67cc, 0.3);
    transition: ease-in-out 0.3s;

    &:hover {
      box-shadow: 1rem 3rem 7rem 0 rgba(#1f67cc, 0.35);
      transition: ease-in-out 0.3s;
    }

    .SelectableCard-Container-p {
      color: #fff;
      margin: 1.5rem 1.5vw 0 1.5vw;
      // display: flex;
      // justify-content: space-between;

      .SelectableCard-Container-p-title {
        display: flex;
        align-items: baseline;
      }
    }
  }

  &.SelectableCard-Container--disabled {
    background-color: #ccc;
    cursor: not-allowed;

    // p {
    //   color: #fff;
    // }
  }

  p {
    font-size: 1.3rem;
    font-weight: 700;
    color: grey;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.SelectableCard-stop {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1vh;
  color: grey;
  background-color: white;
  border-radius: 0.5rem;
  margin: 0.5vh;
  cursor: pointer;
}

.SelectableCard-description {
  color: black;
}
