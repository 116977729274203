@import "../../../ui/settings";

.SettingsCMSSignature_Mirror {
  margin-bottom: 30px;
}

.SettingsCMSSignature {
  display: flex;
  flex-direction: column;
}

.SettingsCMSSignature-form {
  flex: 1 1 auto;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
}

.SettingsCMSSignature-form--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SettingsCMSSignature-preview {
  min-width: 0;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  border-radius: 0.8rem;
}

.SettingsCMSSignature-reset {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: grey;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
  }
}

.SettingsCMSSignature-reset-strong {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: red;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
  }
}

.SettingsCMSSignature-reset-suggestion {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: grey;
}

.SettingsCMSSignature-preview-editor {
  background-color: c("c.white");
  min-width: 0;
  outline: none;
  flex: 1;
  padding: 2rem;
  margin: 2rem;
  margin-top: 0rem;
  padding-top: 0rem;
  word-wrap: break-word;
}

.SettingsCMSSignature-previewLabel {
  @include l_smallest(c("c.g50"));
  margin-bottom: 1.5rem;
  line-height: (20 / 11);

  p {
    font-size: 1.3vh;
    padding: 1vh;
    border: 1px solid #ccc;
    border-radius: 1vh;
    margin: 2vh;
  }
}

.SettingsCMSSignature-previewContent {
  background-color: c("c.white");
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: (20 / 15);
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  flex: 1 1 auto;
}

.SettingsCMSSignature-previewName {
  font-size: 1.8rem;
  font-weight: 500;
}

.SettingsCMSSignature-previewPosition {
  @include smaller;
  margin-bottom: 2rem;
}

.SettingsCMSSignature-previewPhones {
  ul {
    list-style: none;
  }
}

.SettingsCMSSignature-previewWebsite {
  word-break: break-word;

  a {
    color: c("c.s75");
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsCMSSignature-previewContent {
    padding: 3rem;
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsCMSSignature-preview {
    width: 30rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .SettingsCMSSignature-preview {
    width: 32rem;
  }

  .SettingsCMSSignature-form {
    grid-template-columns: repeat(2, 1fr);
  }

  .SettingsCMSSignature-formField--wide {
    grid-column: 1 / 3;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .SettingsCMSSignature-previewName {
    font-size: 2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsCMSSignature-previewName {
    font-size: 2.2rem;
  }

  .SettingsCMSSignature-form {
    row-gap: 4.4rem;
  }
}

a {
  text-decoration: c("cdk.sidebarMenuTextDecoration");
  color: c("c.s90");
}
