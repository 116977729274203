@mixin input {
  background-color: c("c.t");
  color: c("cdk.inputTextColor");
  width: 100%;
  min-height: 4rem;
  font-size: c("cdk.inputFontSize");
  padding: 0.5rem 0;
  border: none;
  border-bottom: 2px solid c("c.shadeB");
  transition: border-color 0.3s;

  &:enabled {
    &:focus,
    &:hover {
      border-color: c("c.shadeC");
    }
  }

  @media screen and (min-width: c("bp.m")) {
    min-height: 4.6rem;
  }

  @media screen and (min-width: c("bp.xxl")) {
    font-size: 1.8rem;
    min-height: 5.4rem;
  }
}
