@import "../../ui/settings";

.Sidebar {
  height: 100%;
  padding-right: 2rem;
  display: flex;
  align-items: center;
}

.Sidebar-menu {
  height: 100%;
  list-style: none;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
}

.Sidebar-menuItem {
  margin: 1rem;
  flex: 0 1 3.4rem;
  position: relative;
}

@media screen and (min-width: c("bp.m")) {
  .Sidebar {
    padding-right: 0;
    flex-direction: column;

    &::before {
      width: 2.4rem;
      height: 0.3rem;
      margin-right: 0;
      margin-bottom: 2.5rem;
    }
  }

  .Sidebar-menu {
    flex-direction: column;
  }

  .Sidebar-menuItem {
    width: 5.4rem;
    max-width: 100%;
    flex-basis: 5.4rem;
    position: relative;
  }
}
