.StatusContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.ActionsArea {
  display: flex;
  flex-direction: row;
}
.StatusContentLabel {
  margin-right: 4vh;
  position: relative;
  bottom: 0.8vh;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  min-width: 24vw;
}

.StatusContentValue {
  margin-left: 1vh;
}
