.CampaignCategories-Container {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  // margin: 5vh 0;

  // ul {
  //   columns: 2;
  //   -webkit-columns: 2;
  //   -moz-columns: 2;
  // }
}

.CampaignCategories-Loader {
  display: flex;
  justify-content: center;
}

.CampaignCategories-Error {
  display: flex;
  justify-content: center;
  font-size: 1.4vh;
}
