@import "../../../../../ui/settings";

.SequenceTemplateEditor-Container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .SequenceCard-sequence {
    display: flex;
  }

  .SequenceCard-input {
    margin: 0 0.5vw;

    input {
      outline: none;
    }
  }

  .SequenceTemplateEditor-BlockTitle {
    font-size: 1.7vh;
    border-bottom: 2px solid #deeaed;
    color: grey;
  }

  .SequenceTemplateEditor-Error {
    display: flex;
    justify-content: center;
  }

  .SequenceTemplateEditor-Text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh 0;
    color: black;
    padding: 0.8rem;
    width: 70%;
    border-radius: 0.3rem;
    border-bottom: 0.2rem solid red;
    box-shadow: 0 0 0.8rem 0 rgba(red, 0.1);
    font-size: 1.4vh;
  }

  .SequenceTemplateEditor-Sequences-Container {
    margin: 3vh 0;
    display: flex;
    flex-direction: column;

    .SequenceTemplateEditor-Sequences {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      transition: top 1s ease-in;
      flex-direction: column;

      .SequenceCard {
        display: flex;
        margin: 1vh;
        padding: 3vh 2rem 0 2rem;
        border-radius: 0.8rem;
        box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
        font-size: 1.4vh;

        .SequenceCard-Section-Small {
          margin: 0 1vw;
        }

        .SequenceCard-Section-Large {
          margin-right: 2vw;
        }

        .SequenceCard-Header {
          font-weight: bold;
        }

        .SequenceCard-sequence {
          display: flex;
          align-items: center;

          .SequenceCard-input {
            margin: 0 0.5vw;

            input {
              outline: none;
            }
          }
        }

        .SequenceCard-answer {
          margin: 2vh 0;
          white-space: pre-wrap;
        }

        .SequenceCard-answer-showMore {
          display: flex;
          justify-content: flex-end;

          span {
            color: grey;
            text-decoration: underline;
            cursor: pointer;
            margin: 1vh 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: c("bp.s")) {
  .SequenceTemplateEditor-Container {
    min-width: 80vw;
    max-width: 80vw;
  }

  .SequenceTemplateEditor-BlockTitle {
    min-width: 80vw;
    max-width: 80vw;
  }

  .SequenceTemplateEditor-Sequences {
    display: flex;
    flex-direction: column;

    .SequenceCard {
      min-width: 70vw;
      max-width: 70vw;
    }
  }

  .SequenceCard-input {
    min-width: 50vw;
  }
}

@media screen and (min-width: c("bp.s")) {
  .SequenceTemplateEditor-Container {
    min-width: 50vw;
    max-width: 50vw;
  }

  .SequenceTemplateEditor-BlockTitle {
    min-width: 50vw;
    max-width: 50vw;
  }

  .SequenceTemplateEditor-Sequences {
    display: flex;
    flex-direction: row;

    .SequenceCard {
      min-width: 40vw;
      max-width: 40vw;
    }
  }
}
