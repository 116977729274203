@import "../../../ui/settings";

.TileSquare-QuestionMark {
    flex-shrink: 0;
    color: c("c.dashboardQuestionMark");
    display: flex;
    position: relative;
    cursor: pointer;
}

.TileSquare--main .TileSquare-QuestionMark {
    color: #fff;
}
