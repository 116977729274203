@import "../../ui/settings";

.PageLogin__root {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
}

.PageLoginCrmaiva__root {
  @extend .PageLogin__root;
  justify-content: center;
}

.PageLogin__error {
  margin: 1vh 0;
}

.PageLogin__illustrations {
  display: none;
}

.PageLogin__error-message {
  .MuiAlert-message:first-letter {
    text-transform: uppercase;
  }
}

.PageLoginCrmaiva__error-message {
  @extend .PageLogin__error-message;
}

.PageLogin__container {
  // flex: 1 0 auto;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: #fff;
  // align-content: center;
}

.PageLoginCrmaiva__container {
  @extend .PageLogin__container;
}

.PageLogin__content {
  padding: 4vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
  //border: 1px solid black;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.PageLoginCrmaiva__content {
  @extend .PageLogin__content;
}

.PageLogin__headLogo {
  display: flex;
  justify-content: center;
  background-color: black;
  width: 90%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  color: white;
  padding: 1rem;
}

.PageLogin__headLogo-img-spincar {
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.PageLogin__headLogo-img-impel {
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.PageLogin__headLogo-word {
  display: flex;
  min-width: 8rem;
  font-size: 20px;
  align-items: center;
  justify-content: center;
}

.PageLogin__heading {
  margin-bottom: 2vh;
  padding: 2vh;
  font-size: 2.1vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .PageLogin__heading-logo {
    width: 60vw;
    margin-bottom: 4vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.PageLoginCrmaiva__heading {
  @extend .PageLogin__heading;
}

.PageLogin__form-container {
  display: flex;
  justify-content: center;

  .PageLogin__form {
    width: 100%;
  }

  .PageLogin__form-contact {
    display: flex;
    justify-content: center;
    margin: 2vh 0;
    font-size: 1.4vh;
  }
}

.PageLogin__footer {
  margin: 5vh 0;

  .PageLogin__link {
    color: #bbb;
    text-decoration: none;
    font-size: 2vh;
    font-weight: bold;
    transition: 0.5s ease-in-out;

    &:hover {
      opacity: 0.6;
      transition: 0.5s ease-in-out;
    }
  }
}

@media (min-width: c("bp.m")) {
  .PageLogin__illustrations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 50vw;
    max-width: 50vw;
    overflow: hidden;
    background-image: url("../../assets/img/login/background.png");
    background-size: cover;

    .checklist {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      width: 40vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .girl {
      position: absolute;
      bottom: 0;
      left: 10%;
      display: flex;
      justify-content: center;
      width: 15vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .chat {
      position: absolute;
      right: 8%;
      bottom: 3%;
      display: flex;
      justify-content: center;
      width: 25vw;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .PageLogin__container {
    min-width: 50vw;
    max-width: 50vw;
  }

  .PageLogin__form-container {
    width: 90%;
  }

  .PageLogin__footer {
    margin: 5vh 0;

    .PageLogin__link {
      color: #bbb;
      text-decoration: none;
      font-size: 2vh;
      font-weight: bold;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 0.6;
        transition: 0.5s ease-in-out;
      }
    }
  }

  .PageLogin__content {
    padding: 4vh;
    width: 70%;
  }

  .PageLogin__headLogo {
    width: 70%;
  }

  .PageLogin__heading {
    .PageLogin__heading-logo {
      width: 20vw;
    }
  }
}

.PageLoginCrmaiva__heading-logo {
  @extend .PageLogin__heading-logo;
}

@media (min-width: c("bp.xl")) {
  .PageLogin__form-container {
    width: 70%;
  }
}
