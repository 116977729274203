@import "../../../ui/settings";

.ConversationsDetails {
  background-color: c("c.g01");
  width: #{"min(85%, 29rem)"};
  // padding: 2rem;
  border-left: 1px solid c("c.shadeA");
  box-shadow: -2rem 0 5rem 0 rgba(c("c.black"), 0.1);
  position: fixed;
  top: 7rem;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.ConversationsDetails-heading {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid c("c.shadeB");
}

.ConversationsDetails-name {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.ConversationsDetails-toggler {
  @include smallest(c("c.danger"));
  background-color: c("c.t");
  padding-right: 0;
  padding-left: 0;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;

    .ConversationsDetails-togglerIcon {
      transform: translateX(0.5rem);
    }
  }
}

.ConversationsDetails-togglerLabel {
  flex: 1 0 auto;
}

.ConversationsDetails-togglerIcon {
  color: c("c.s90");
  margin-left: 1.7rem;
  transition: transform 0.2s;
}

.ConversationsDetails-info {
  display: grid;
  grid-template-columns: 40% 60%;
  row-gap: 0.2rem;
}

.ConversationsDetails-infoLabel {
  @include smallest(c("cdk.smallTitleTextColor"));
  padding-top: 0.5rem;
  padding-right: 1.5rem;
  line-height: 1.2;

  &.is-full {
    padding-top: 1.8rem;
    grid-column: 1 / 3;
  }
}

.ConversationsDetails-infoValue {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3;
  word-break: break-word;

  strong {
    color: c("c.s90");
    font-weight: inherit;
  }

  &.is-full {
    padding-bottom: 2.2rem;
    grid-column: 1 / 3;
  }
}

.ConversationsDetails-actions {
  padding: 2rem 0;
}

.ConversationsDetails-action {
  @include smallest;
  background-color: c("c.white");
  color: c("c.s90");
  width: 100%;
  min-height: 3.8rem;
  padding: 0.5rem 1.5rem;
  text-align: left;
  border: 1px solid c("c.shadeA");
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .ConversationsDetails-actionIcon {
      transform: translateX(0.5rem);
    }
  }
}

.ConversationsDetails-actionLabel {
  flex: 1 0 auto;
}

.ConversationsDetails-actionIcon {
  color: c("c.s90");
  transition: transform 0.2s;
}

.ConversationsDetails-action.is-danger {
  .ConversationsDetails-actionIcon {
    color: c("c.danger");
  }
}

.ConversationsDetails-action + .ConversationsDetails-action {
  margin-top: 1rem;
}

.ConversationsDetails-objectName {
  margin-bottom: 1rem;
}

.ConversationsDetails-objectInfo {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.ConversationsDetails-objectValue {
  color: c("c.s90");
  font-size: 2.2rem;
  font-weight: 500;
  flex: 1 1 auto;
}

.ConversationsDetails-objectStatus {
  color: c("c.white");
  min-height: 3.6rem;
  padding: 0.3rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConversationsDetails-objectStatus--available {
  background-color: c("c.positive");
}

.ConversationsDetails-objectStatus--unavailable {
  background-color: c("c.danger");
}

.ConversationsDetails-objectPhoto {
  background-color: c("c.g50");
  margin-top: 2rem;
  margin-bottom: 3rem;

  img {
    background-color: c("c.g50");
    max-width: 100%;
    display: block;
    aspect-ratio: 248 / 180;
  }
}

.ConversationsDetails-notification {
  display: flex;
}

.ConversationsDetails-notification + .ConversationsDetails-notification {
  margin-top: 1.5rem;
}

.ConversationsDetails-notificationIcon {
  color: c("c.s90");
  width: 1.8rem;
  margin-right: 1.6rem;
  flex: 0 0 auto;
}

.ConversationsDetails-notificationText {
  color: c("c.g70");
  padding: 0.4rem 0;
  font-size: 1.5rem;
  line-height: 1.2;
}

.ConversationsDetails-notification {
  &.is-important {
    .ConversationsDetails-notificationIcon {
      color: c("c.danger");
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .ConversationsDetails {
    top: 10.2rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .ConversationsDetails {
    width: 30rem;
    // padding: 2.8rem 3rem;
    position: static;
    box-shadow: none;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .ConversationsDetails-notification + .ConversationsDetails-notification {
    margin-top: 2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ConversationsDetails {
    width: 34.5rem;
    // padding: 3.6rem 4rem;
  }

  .ConversationsDetails-heading {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  .ConversationsDetails-name {
    font-size: 2.2rem;
  }

  .ConversationsDetails-actions {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .ConversationsDetails-action {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .ConversationsDetails-objectValue {
    font-size: 2.6rem;
  }

  .ConversationsDetails-objectPhoto {
    margin-bottom: 4rem;
  }

  .ConversationsDetails-notification + .ConversationsDetails-notification {
    margin-top: 2.5rem;
  }
}
