.SpecialBusiness_form{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.SpecialBusiness_elements {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 46px;

    & .BusinessHoursName{
        margin-right: 30px;

        & .FormTextfield-error {
            font-size: 1vh;
            position: absolute;
            bottom: -15px;
            left: 0;
            color: red;
        }
    }

    & .SpecialBusiness_form_isOpen{
        margin: 0 30px 0 30px;
    }

    & .BusinessHoursDate{
        width: 130px;
    }
}