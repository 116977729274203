@import "../../../../../ui/settings";

.appointments_box{
    width: 70%;
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    & .selectBox{
        margin: 0 29px 0 35px;
    }

    & p {
        font-size: 16px;
        width: 180px;
    }
}
