@import "../../../ui/settings";

.SettingsAccess--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SettingsAccess-row--stateOff {
  color: rgba(c("c.p90"), 0.25);
}

.SettingsAccess-cell--source {
  display: flex;
  align-items: center;
}

.SettingsAccess-cell--switcher_source {
  max-width: 70px;
  font-size: 7px;
  max-height: 25px;
  margin: 0 10px 0 10px;
}

.SettingsAccess-cell--source-label {
  padding-left: 10px;
  width: 100%;
}

.SettingsAccess-cell--sub-source {
  color: c("cdk.subSourcesLinkColor");
  text-decoration: underline;
  font-size: c("cdk.smallFontSize");
  cursor: pointer;
  text-transform: c("cdk.subHeaderTextTransform")
}

.SettingsAccess-cell--expand_button {
  //float: right;
  //display: flex;
  margin-left: 5px;
  align-items: center;
  //padding-top: 10px;
  cursor: pointer;
}
