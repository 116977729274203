.CSVUploaderWrapper-container {
  .CSVUploaderWrapper-title {
    font-size: 1.7vh;

    .CSVUploaderWrapper-title-meta {
      font-size: 1.5vh;
      margin: 0 0.5vw;
      cursor: pointer;
      color: #92b0b4;
      text-decoration: underline;
    }
  }
}

.CSVUploader-container {
  .CSVUploader-control {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .CSVUploader-with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.CSVUploader-Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

input[type="file"] {
  display: none;
}
