@import "../../../ui/settings";

.PaginationPage {
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0.2rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: c("cdk.generalTextTransform");
  color: c("c.s90");
  letter-spacing: 0.05em;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;

  &:not(.is-disabled, .is-current) {
    &:focus,
    &:hover {
      background-color: c("c.shadeB");
    }
  }

  &.is-first,
  &.is-prev,
  &.is-next,
  &.is-last {
    background-color: c("c.shadeA");
  }

  &.is-current {
    background-color: c("c.s90");
    color: c("c.white");
  }

  &.is-disabled {
    opacity: 0.5;
  }
}
