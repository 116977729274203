@import "../../ui/settings";

.Chats {
  height: 100%;
  display: grid;
}

@media screen and (min-width: c("bp.m")) {
  .Chats {
    grid-template-columns: 24rem auto;
  }
}

@media screen and (min-width: c("bp.l")) {
  .Chats {
    grid-template-columns: 28.5rem auto minmax(0, max-content);
  }
}
