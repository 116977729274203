@import "../../../../ui/settings";

.SettingsCMSForm {
  min-width: 0;
  padding: 0 2rem 2rem;
  position: relative;

  .FormTextfield-input {
    border-color: c("c.shadeF");
  }

  .FormCheckbox:not(.FormCheckbox--checked) .FormCheckbox-label {
    color: rgba(c("c.p90"), 0.35);
  }

  .FormCheckbox-fakeInput {
    border-color: c("c.g15");
  }
}

.SettingsCMSForm--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SettingsCMSForm-frame {
  background-color: #F1F1F1; 
  width: 100%;
  height: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 0 0 0.8rem 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  &::before {
    content: "";
    background-color: c("c.shadeA");
    height: 3.8rem;
    border-right: 1px solid c("c.shadeF");
    border-left: 1px solid c("c.shadeF");
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 100%;
  }

  &::after {
    width: 6rem;
    height: 6rem;
    border: 1px solid c("c.shadeF");
    border-radius: 50%;
    box-shadow: 0 0 0 2.9rem c("c.shadeA");
    position: absolute;
    bottom: 100%;
  }
}

.SettingsCMSForm-textarea {
  width: 100%;
  padding: 28px 31px 92px 31px;
  font: normal normal normal 14px HK Grotesk;
  letter-spacing: 0.07px;
  color: #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
}

.SettingsCMSForm-errors {
  background-color: rgba(c("c.s75"), 1);
  color: c("c.white");
  margin-bottom: 3rem;
  padding: 1.5rem;
  font-size: 1.4rem;
}

.SettingsCMSForm-primary {
  display: grid;
  gap: 3rem;
}

.SettingsCMSForm-field--checkboxes {
  align-self: flex-end;
}

.SettingsCMSForm-group {
  margin-top: 3rem;
}

.SettingsCMSForm-groupTitle {
  @include accent;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: (20 / 18);
}

.SettingsCMSForm-groupContent {
  display: grid;
  gap: 2rem 3rem;
}

.SettingsCMSForm-principals {
  margin-left: 4.1rem;
}

.SettingsCMSForm-principal {
  margin-top: 1rem;
}

.SettingsCMSForm-actions {
  margin-top: 3rem;
}

.SettingsCMSForm-save{

  & .FormSubmit-button[disabled]{
    background-color: #b2b2b2;
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsCMSForm-primary {
    grid-template-columns: repeat(2, 1fr);
  }

  .SettingsCMSForm-groupContent {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsCMSForm {
    padding-top: 3.8rem;
    padding-bottom: 3rem;
    padding-right: 4rem;
    padding-left: 4rem;
    grid-column: 1 / 3;

    &:nth-child(2n) {
      .SettingsCMSForm-frame {
        border-top-right-radius: 0.8rem;

        &::before {
          right: calc(50% + 1.5rem);
        }

        &::after {
          margin-left: -1px;
          left: calc(50% - 1.5rem);
          clip: rect(2.9rem 2.9rem auto auto);
        }
      }
    }

    &:nth-child(2n - 1) {
      .SettingsCMSForm-frame {
        border-top-left-radius: 0.8rem;

        &::before {
          left: calc(50% + 1.5rem);
        }

        &::after {
          margin-right: -1px;
          right: calc(50% - 1.5rem);
          clip: rect(2.9rem auto auto 2.9rem);
        }
      }
    }
  }

  .SettingsCMSForm-frame {
    &::after {
      content: "";
    }
  }

  .SettingsCMSForm-primary {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: c("bp.l")) {
  .SettingsCMSForm-groupContent {
    grid-template-columns: repeat(3, 1fr);
  }

  .SettingsCMSForm-field--submit {
    grid-column: 3 / 4;

    .FormSubmit-button {
      width: auto;
    }
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsCMSForm {
    padding-top: 5.8rem;
    padding-bottom: 5rem;
  }

  .SettingsCMSForm-primary {
    gap: 3rem;
  }

  .SettingsCMSForm-principals {
    margin-left: 5.2rem;
  }
}

.SettingsCMSForm-checkbox-container {
  display: flex;
  align-items: center;

  .questionmark {
    display: flex;
    margin: 1vh;
  }
}
