@import "../../../ui/settings";

.SidebarMenuLink {
  height: 100%;
}

.SidebarMenuLink-button {
  color: c("c.shadeC");
  height: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    color: c("c.iconHover");
  }

  &.is-active {
    color: c("c.iconActive");
  }

/*   &:hover {
    color: c("c.s90");
  }

  &.is-active {
    color: c("c.p90");
  } */

  &::after {
    width: 100%;
    height: 1rem;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.SidebarMenuLink-button.is-open {
  &::after {
    content: "";
  }
}

.SidebarMenuLink-button:not(.is-open) + .SidebarMenuLink-submenu {
  display: none;
}

.SidebarMenuLink-submenu {
  /* background-color: c("c.s75"); */
  background-color: c("cdk.sidebarMenuBackgroundColor");
  color: c("c.white");
  width: max-content;
  max-width: calc(100vw - 4rem);
  border-radius: 0.8rem;
  list-style: none;
  position: absolute;
  top: calc(100% + 1rem);
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.SidebarMenuLink-submenuItem + .SidebarMenuLink-submenuItem {
  border-top: 1px solid rgba(c("c.shadeA"), 0.15);
}

.SidebarMenuLink-submenuLink {
  @include smaller;
  padding: 1.5rem 2rem;
  line-height: 1;
  display: block;

  &:hover {
    background-color: c("cdk.sidebarMenuBackgroundHoverColor");
    transition: background-color 0.25s;
  }
}

@media screen and (min-width: c("bp.m")) {
  .SidebarMenuLink-button {
    height: 100%;
    max-height: 5.4rem;
  }

  .SidebarMenuLink-button.is-open {
    &::after {
      content: none;
    }
  }

  .SidebarMenuLink-submenu {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SidebarMenuLink-button {
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .SidebarMenuLink-submenuLink {
    padding: 2rem 2.5rem;
  }
}
