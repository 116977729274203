@import "../../../ui/settings";

.FormSelect {
  width: 100%;
  position: relative;

  &.FormSelect--iconed {
    .FormSelect-toggler {
      padding-left: 3rem;
    }
  }
}

.FormSelect-label:not(.FormSelect-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.FormSelect-label--hidden {
  @include sr-only;
}

.FormSelect-field {
  position: relative;
}

.FormSelect-fieldIcon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.FormSelect-toggler {
  @include input;
  text-align: left;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:focus,
  &:hover {
    outline: none;
  }
}

.FormSelect-toggler--disabled > .FormSelect-togglerText,
.FormSelect-toggler--disabled > .FormSelect-togglerIcon {
  color: c("c.g50");
}

.FormSelect-togglerText {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 auto;
  overflow: hidden;
}

.FormSelect-list {
  background-color: c("c.white");
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;

  & > div {
    padding-inline: 1rem;
  }

  & > ul {
    max-height: 30rem;
    overflow-y: auto;
  }
}

.WidgetAccount .FormSelect-list {
  overflow-x: hidden;
}

.FormSelect:not(.FormSelect--open) .FormSelect-list {
  display: none;
}

.FormSelect-listItem {
  list-style: none;
  min-height: 4.5rem;
  padding: 0.5rem 3rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: c("cdk.subHeaderTextTransform");
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  &.FormSelect-listItems--active {
    color: c("c.s90");
  }

  &.FormSelect-listItems--disabled {
    color: c("c.g20");
    cursor: not-allowed;
  }

  &.FormSelect-listItems--selected {
    text-decoration: underline;
  }
}

.FormSelect-listItem + .FormSelect-listItem {
  border-top: 1px solid c("c.shadeA");
}
