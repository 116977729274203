@import "../../../ui/settings";

.FormRadio {
  user-select: none;
}

.FormRadio.FormRadio--focused {
  .FormRadio-fakeInput {
    border-color: c("c.shadeC");
  }
}

.FormRadio-field {
  display: flex;
  align-items: center;
  position: relative;
}

.FormRadio-input {
  @include sr-only;
}

.FormRadio-fakeInput {
  background-color: c("c.t");

  width: 2.2rem;
  height: 2.2rem;
  border: 2px solid c("c.shadeF");
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  transition: border-color 0.25s;
  cursor: pointer;
}

.FormRadio-dot {
  background-color: c("c.s75");
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}

.FormRadio-label {
  margin-left: 1.5rem;
  cursor: pointer;
  user-select: text;
}

@media screen and (min-width: c("bp.xxl")) {
  .FormRadio-fakeInput {
    width: 2.6rem;
    height: 2.6rem;
  }

  .FormRadio-label {
    margin-left: 3rem;
  }
}
