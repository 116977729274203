@import "../../ui/settings";

.AppSwitch {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #F1F2FA;
  padding: 0.4rem 1rem;
}

.impelAppsSign {
  @include smallest(c("c.d70"));
  margin-right: 2rem;
}

.appSwitchLink {
  @include smallest(c("c.s90"));
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.externalLinkImage {
  height: 100%;
  margin-left: 0.5rem;
}