@mixin smallest($color: inherit) {
  color: $color;
  font-size: c("cdk.settingsSubHeaderFontSize");
  font-weight: c("cdk.smallTitleFontWeight");
  text-transform: c("cdk.cardLabelTextTransform");
  letter-spacing: 0.05em;

  @media screen and (min-width: c("bp.xxl")) {
    font-size: c("cdk.settingsSubHeaderBiggerFontSize");
  }
}

@mixin l_smallest($color: inherit) {
  color: $color;
  font-size: c("cdk.settingsSubHeaderFontSize");
  font-weight: c("cdk.smallTitleFontWeight");
  letter-spacing: 0.05em;

  @media screen and (min-width: c("bp.xxl")) {
    font-size: c("cdk.settingsSubHeaderBiggerFontSize");
  }
}

@mixin smaller($color: inherit) {
  color: $color;
  font-size: c("cdk.settingsSubHeaderBiggerFontSize");
  font-weight: c("cdk.smallTitleFontWeight");
  text-transform: c("cdk.generalTextTransform");
  letter-spacing: 0.05em;

  @media screen and (min-width: c("bp.xxl")) {
    font-size: c("cdk.settingsSubHeaderBiggestFontSize");
  }
}

@mixin larger {
  font-size: 2.6rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  @media screen and (min-width: c("bp.xl")) {
    font-size: 3.2rem;
  }

  @media screen and (min-width: c("bp.xxl")) {
    font-size: 4.2rem;
  }
}

@mixin huge {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  @media screen and (min-width: c("bp.xl")) {
    font-size: 3.6rem;
  }

  @media screen and (min-width: c("bp.xxl")) {
    font-size: 4.8rem;
  }
}

@mixin accent {
  font-size: c("cdk.settingsSubHeaderFontSize");

  @media screen and (min-width: c("bp.xxl")) {
    font-size: c("cdk.settingsSubHeaderBiggestFontSize");
  }
}

@mixin heading-secondary {
  font-size: 2rem;
  font-weight: 500;

  @media screen and (min-width: c("bp.xxl")) {
    font-size: 2.8rem;
  }
}
