@import "../../../ui/settings";

.DashboardCharts {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 3rem;
}

.DashboardCharts-bars {
  display: flex;
  flex-direction: column;
}

.ChartSummary-title {
  @include smallest;
  background-color: c("c.shadeA");
  padding: 1rem;
  letter-spacing: 0.025em;
  border: 1px solid c("c.shadeA");
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

.ChartSummary-bar {
  border-right: 1px solid c("c.shadeA");
  border-left: 1px solid c("c.shadeA");
  border-bottom: 1px solid c("c.shadeA");
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    stroke-width: 22%;
  }
}

.ChartSummary-barWrapper {
  position: relative;
}

.ChartSummary-bar--positive {
  stroke: c("c.s90");
  stroke-dashoffset: 0;
}

.ChartSummary-bar--neutral {
  stroke: c("c.g10");
}

.ChartSummary-bar--negative {
  stroke: c("c.danger");
}

.ChartSummary-barSeparator {
  background-color: c("c.white");
  width: 2px;
  height: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: bottom center;
}

.ChartSummary-barHighlights {
  width: 50%;
  height: 50%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: absolute;
  top: 25%;
  left: 25%;
}

.ChartSummary-barPrimary {
  color: c("c.s90");
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  flex: 0 0 100%;
}

.ChartSummary-barTitle {
  @include smallest;
  margin-top: 0.7rem;
  letter-spacing: 0.025em;
  flex: 0 0 100%;
}

.ChartSummary-barSecondary {
  margin-top: 0.6rem;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.2;
  flex: 0 0 100%;
}

.ChartSummary-legend {
  padding: 1rem 2rem;
  border-right: 1px solid c("c.shadeA");
  border-left: 1px solid c("c.shadeA");
  border-bottom: 1px solid c("c.shadeA");
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;

  display: flex;
  align-items: center;
}

.ChartSummary-legendRows {
}

.ChartSummary-legendRow {
  span {
    display: inline-block;
  }

  &.ChartSummary-legendRow--positive {
    color: c("c.s90");
  }
  &.ChartSummary-legendRow--negative {
    color: c("c.danger");
  }
  &.ChartSummary-legendRow--neutral {
    color: c("c.g10");
  }
}

.ChartSummary-legendRow + .ChartSummary-legendRow {
  margin-top: 2rem;
}

.ChartSummary-legendIcon {
  background-color: currentColor;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
  border-radius: 50%;
  vertical-align: middle;
}

.ChartSummary-legendLabel {
  @include smallest(c("c.p70"));
  min-width: 6rem;
  letter-spacing: 0.025em;
}

.ChartSummary-legendPrimary {
  @include huge;
  min-width: 5rem;
  text-align: right;

  .ChartSummary-legendRow--neutral & {
    color: c("c.p70");
  }
}

.ChartSummary-legendSecondary {
  color: c("c.p90");
  padding-left: 2.5rem;
  font-size: 2rem;
  font-weight: 500;
}

@media screen and (min-width: c("bp.m")) {
  .DashboardCharts-summary {
    display: grid;
    grid-template-rows: min-content auto;
    grid-template-columns: repeat(2, 1fr);
  }

  .ChartSummary-title {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    grid-area: 1 / 1 / 2 / 3;
  }

  .ChartSummary-bar {
    border-right: none;
    border-bottom-left-radius: 0.8rem;
  }

  .ChartSummary-legend {
    border-left: none;
    border-bottom-left-radius: 0;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .DashboardCharts {
    grid-template-columns: 42% 58%;
  }

  .DashboardCharts-bars {
    .TableContainer-table {
      min-width: initial !important;
      border-top-right-radius: 0 !important;
    }

    .Table-expandButton {
      border-bottom-right-radius: 0;
    }
  }

  .ChartSummary-title {
    border-top-left-radius: 0;
  }

  .ChartSummary-bar {
    border-left: none;
    border-bottom-left-radius: 0;
  }

  .ChartSummary-legendSecondary {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ChartSummary-title {
    padding: 1.8rem 3rem;
  }

  .ChartSummary-legendLabel {
    width: 7rem;
  }

  .ChartSummary-legendPrimary {
    min-width: 8rem;
  }

  .ChartSummary-legendSecondary {
    font-size: 2.4rem;
  }
}
