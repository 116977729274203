@import "../../../../../ui/settings";

.CampaignCSVUploader-Container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .CampaignCSVUploader {
    display: flex;
    flex-direction: column;
    margin: 1vh;
  }

  .CampaignCSVUploader-BlockTitle {
    font-size: 1.7vh;
    border-bottom: 2px solid #deeaed;
    color: grey;
  }

  .CampaignCSVUploader-Settings-Container {
    margin: 3vh 0;
    display: flex;
    flex-direction: column;
  }

  .CampaignCSVUploader {
    display: flex;
    justify-content: center;
  }

  .ConditionCard-Container {
    background-color: green;
    margin: 2vh;
    padding: 2vh 4vw;
  }

  .Condition-Card {
    margin: 1vh;
    padding: 2rem;
    border-radius: 0.8rem;
    box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
    font-size: 1vh;
    display: flex;
    cursor: pointer;

    &:hover {
      box-shadow: 1rem 3rem 7rem 0 rgba(black, 0.15);
      transition: box-shadow 0.2s;
    }

    &.Condition-Card--active {
      background-color: #1f67cc;

      p {
        color: #fff;
      }

      svg {
        fill: #fff;
        cursor: pointer;

        &:hover {
          fill: #ccc;
        }
      }
    }
  }
}

@media screen and (max-width: c("bp.s")) {
  .CampaignCSVUploader-Container {
    min-width: 80vw;
    max-width: 80vw;
  }

  .CampaignCSVUploader-BlockTitle {
    min-width: 80vw;
    max-width: 80vw;
  }
}

@media screen and (min-width: c("bp.s")) {
  .CampaignCSVUploader-Container {
    min-width: 50vw;
    max-width: 50vw;
  }

  .CampaignCSVUploader-BlockTitle {
    min-width: 50vw;
    max-width: 50vw;
  }
}
