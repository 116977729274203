@import "../../ui/settings";

.progressBarContainer{
  width: 90%;
  margin: 0 auto;
  padding: 10px 0;
}

.header {
  display: flex;
  justify-content: space-between;
}  

.bar {
  display: flex;
  margin: 5px 0;
}

.bar :first-child {
  height: 16px;
  background: c("c.dashboardProgresbar") 0% 0% no-repeat padding-box;
  border-radius: 8px 0px 0px 8px;
  margin-right: 1px;
}  

.bar :last-child {
  height: 16px;
  border: 2px solid #CCCCCC;
  border-radius: 0px 8px 8px 0px;
  margin-left: 1px;
}

.bar  .barError{
  height: 16px;
  border: 2px solid #CCCCCC;
  background: none;
  border-radius: 8px 8px 8px 8px;
  margin-left: 1px;
}

.value {
  display: flex;
  justify-content: space-between;
}  
.progressBarContainer p{
  display: flex;
  align-items: center;
  font-size: c("cdk.ordinaryFontSize");
  letter-spacing: 0px;
  color: #5A5A5A;
  font-weight: c("cdk.smallTitleDashboardFontWeight");
}

.progressBarContainer p span{
  font-size: c("cdk.progressBarTextSize");
  letter-spacing: 0px;
  padding-right: 5px;
  font-weight: 500;
  color: #000;
  line-height: normal;
}

.redirectLink{
  color: c("c.dashboardProgresbar");
  cursor: pointer;
}