@import "../../../ui/settings";

.ConversationsButton {
  @include smaller;
  background-color: c("cdk.buttonFillColor");
  width: 100%;
  min-height: 3rem;
  letter-spacing: 0.025em;
  border: c("cdk.conversationsButtonBorder");
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: c("cdk.conversationsButtonFontSize");
  font-weight: c("cdk.conversationsButtonFontWeight");
  transition: background-color 0.3s, color 0.3s;
  color: c("cdk.buttonTextColor");
  text-transform: capitalize;

  &:focus,
  &:hover {
    background-color: c("cdk.buttonHoverFillColor");
    color: c("cdk.buttonHoverTextColor");
    outline: none;
  }
}

@media screen and (min-width: c("bp.m")) {
  .Conversations-button {
    min-height: 3.6rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ConversationsButton {
    min-height: 4.2rem;
  }
}
