@import "../../../ui/settings";

.TileDetails {
  min-width: 0;
  padding: 2.5rem 1.5rem;
  display: grid;

  &.is-important {
    position: relative;

    .TileDetails-label {
      padding-right: 2rem;
    }

    .TileDetails-primary {
      color: c("c.danger");
    }
  }
}

.TileDetails-icon {
  color: c("c.danger");
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.3rem;
  right: 1.3rem;
}

.TileDetails-label {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 1.6rem;
  word-break: break-word;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TileDetails-data {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.TileDetails-primary {
  font-size: 2.6rem;
  font-weight: 500;
  flex: 1 1 auto;
}

.TileDetails-secondary {
  font-size: 1.7rem;
  font-weight: 500;
  flex: 0 0 auto;
  transform: translateY(-0.125em);
}

.TileDetails {
  position: relative;

  &::before {
    background-color: c("c.white");
    width: 0.2rem;
    position: absolute;
    left: -0.8rem;
    top: 3rem;
    bottom: 3rem;
  }

  &::after {
    background-color: c("c.white");
    height: 0.2rem;
    position: absolute;
    bottom: -0.8rem;
    left: 3rem;
    right: 3rem;
  }

  &[data-number="2"],
  &[data-number="4"] {
    &::before {
      content: "";
    }
  }

  &[data-number="1"],
  &[data-number="2"] {
    &::after {
      content: "";
    }
  }
}

.TileDetails--clickable {
  cursor: pointer;

  .TileDetails-label {
    transition: color 0.2s;
  }

  &:hover {
    .TileDetails-label {
      color: c("c.g75");
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .TileDetails {
    &[data-number="3"] {
      &::before {
        content: "";
      }
    }

    &[data-number="1"],
    &[data-number="2"] {
      &::after {
        content: none;
      }
    }
  }
}

@media screen and (min-width: c("bp.xl")) {
  .TileDetails {
    &.is-important {
      .TileDetails-label {
        padding-right: 4rem;
      }
    }
  }

  .TileDetails-icon {
    width: 3.8rem;
    height: 3.8rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .TileDetails {
    padding: 2.8rem 3rem;
  }

  .TileDetails {
    &.is-important {
      .TileDetails-label {
        padding-right: 2rem;
      }
    }
  }

  .TileDetails-label {
    margin-bottom: 3.2rem;
  }
}
