@import "../../ui/settings";

.UserManagementTable {
  height: 100%;

  display: grid;
  grid-template-rows: auto min-content;
  grid-template-columns: 100%;
}

.UserManagementTable-table {
  overflow: auto;
}

.UserManagementTable-contacts {
  display: grid;
  grid-template-columns: max-content auto;

  a{
    color: c("c.s90");;
  }
}

.UserManagementTable-contactLabel {
  color: c("c.g30");
  padding-right: 1rem;
}

.UserManagementTable-summary {
  background-color: c("c.white");
  border-top: 1px solid c("c.shadeA");
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.UserManagementTable-info {
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: c("cdk.generalTextTransform");
  letter-spacing: 0.05em;
  flex: 0 0 100%;
}

.UserManagementTable-infoLabel {
  color: c("c.g50");
}

.UserManagementTable-pagination {
  padding: 1rem;
  flex: 0 0 auto;
}

@media screen and (min-width: c("bp.s")) {
  .UserManagementTable-info {
    text-align: left;
    flex-grow: 1;
    flex-basis: auto;
  }
}

@media screen and (min-width: c("bp.m")) {
  .UserManagementTable-summary {
    min-height: 7rem;
  }

  .UserManagementTable-info {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .UserManagementTable-pagination {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
