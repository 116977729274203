@import "../../ui/settings";

.Dashboard {
  height: 100%;
  padding: 3rem 2rem;
  overflow-y: auto;
}

.DashboardPanel + .DashboardPanel {
  margin-top: 5rem;
}

.DashboardPanel-title {
  @include heading-secondary;
  margin-bottom: c("cdk.dashboardTitleBottomMargin");
  // padding-bottom: 2rem;
  //border-bottom: 1px solid c("c.shadeB");
}

.DashboardPanel-definition{
  display: flex;
}

.DashboardPanel--tiles {
  display: grid;
  gap: 1.8rem;
}

.tableContainer {
  min-height: 160px;
  box-shadow: c("cdk.cardBoxShadow") !important;
  border: c("cdk.borderForBoxShadow");
  border-radius: c("cdk.commonBorderRadius") !important;
}

@media screen and (min-width: c("bp.s")) {
  .Dashboard {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media screen and (min-width: c("bp.m")) {
  .DashboardPanel--tiles {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: c("bp.l")) {
  .DashboardPanel--tiles {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: c("bp.xl")) {
  .Dashboard {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .DashboardPanel + .DashboardPanel {
    margin-top: 8rem;
  }

  .DashboardPanel-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .Dashboard {
    padding: 10rem;
  }

  .DashboardPanel + .DashboardPanel {
    margin-top: 12rem;
  }

  .DashboardPanel-title {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
