@import "../../../ui/settings";

.ChatsList {
  background-color: c("c.g01");
  width: #{"min(85%, 26rem)"};
  border-right: 1px solid c("c.shadeA");
  box-shadow: 2rem 0 5rem 0 rgba(c("c.black"), 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 7rem;
  bottom: 0;
  overflow: hidden;
  order: -1;
}

.ChatsList-heading {
  padding: 1rem 2rem;
  border-bottom: 1px solid c("c.shadeA");
  flex: 0 0 auto;
}

.ChatsList-toggler {
  @include smallest(c("c.s90"));
  background-color: c("c.t");
  padding-right: 0;
  padding-left: 0;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
  }
}

.ChatsList-togglerLabel {
  margin-left: 1.7rem;
  flex: 1 0 auto;
}

.ChatsList-togglerIcon {
  color: c("c.p90");
  transform: rotateZ(180deg);
}

.ChatsList-container {
  flex: 1 1 auto;
  overflow-y: auto;
}

.ChatsList-summary {
  background-color: c("c.white");
  border-top: 1px solid c("c.shadeA");
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChatsList-pagination {
  padding: 1rem;
}

@media screen and (min-width: c("bp.m")) {
  .ChatsList {
    width: 100%;
    height: 100%;
    position: static;
    box-shadow: none;
  }

  .ChatsList-summary {
    min-height: 7rem;
  }
}
