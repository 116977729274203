@import "../../../ui/settings";

.SettingsSignature {
  display: grid;
  row-gap: 3rem;
  column-gap: 4rem;
}

.SettingsSignature-form {
  flex: 1 1 auto;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;
  column-gap: 3rem;
}

.SettingsSignature-form--disabled {
  opacity: 0.5;
  pointer-events: none;
}

// .SettingsSignature-addPhone {
//   background-color: c("c.t");
//   padding: 0;
//   border: none;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   position: absolute;
//   top: 50%;
//   right: 0;
//   transform: translateY(-50%);

//   &:focus,
//   &:hover {
//     outline: none;

//     .SettingsSignature-addPhoneIcon {
//       color: c("c.s90");
//     }
//   }
// }

// .SettingsSignature-addPhoneIcon {
//   color: c("c.g10");
//   transition: color 0.2s;
// }

// .SettingsSignature-addPhoneLabel {
//   font-size: 1.8rem;
//   font-weight: 400;
// }

// .SettingsSignature-addPhoneIcon + .SettingsSignature-addPhoneLabel {
//   margin-left: 1.5rem;
// }

.SettingsSignature-preview {
  min-width: 0;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: c("cdk.settingsCardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  border-radius: c("cdk.commonBorderRadius");
}

.SettingsSignature-reset {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: grey;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
  }
}

.SettingsSignature-reset-strong {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: red;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.4;
  }
}

.SettingsSignature-reset-suggestion {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  color: grey;
}

.SettingsSignature-previewContent {
  background-color: c("c.white");
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: (20 / 15);
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  flex: 1 1 auto;
}

.SettingsSignature-previewName {
  font-size: 1.8rem;
  font-weight: 500;
}

.SettingsSignature-previewPosition {
  @include smaller;
  margin-bottom: 2rem;
}

.SettingsSignature-previewPhones {
  ul {
    list-style: none;
  }
}

.SettingsSignature-previewWebsite {
  word-break: break-word;

  a {
    color: c("c.s75");
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsSignature-previewContent {
    padding: 3rem;
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsSignature {
    grid-template-columns: auto max-content;
  }

  .SettingsSignature-preview {
    width: 30rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .SettingsSignature-preview {
    width: 32rem;
  }

  .SettingsSignature-form {
    grid-template-columns: repeat(2, 1fr);
  }

  .SettingsSignature-formField--wide {
    grid-column: 1 / 3;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .SettingsSignature-previewName {
    font-size: 2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsSignature-previewName {
    font-size: 2.2rem;
  }

  .SettingsSignature-form {
    row-gap: 4.4rem;
  }
}

a {
  text-decoration: underline;
  color: c("c.s90");
}

.SettingsPreferences-Container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin: 0 auto;
  padding: 3vh;
  background-color: c("c.white");
  padding: 5rem;
  border-radius: c("cdk.commonBorderRadius");
  box-shadow: c("cdk.settingsCardBoxShadow");
  border: c("cdk.borderForBoxShadow");
}

.SettingsPreferences-Select {
  margin: 1vh 0;
}

.SettingsPreferences-Actions {
  display: flex;
  justify-content: center;
  margin: 3vh 0;
}
