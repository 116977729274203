@import "../../../ui/settings";

.LatestVehicleInterestArea {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin-top: 30px;
  width: 100%;
  border-top: 1px solid #dee3ee;
}

.ConversationsDetails-objectName {
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 700;
}

.ConversationsDetails-objectInfo {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.ConversationsDetails-objectValue {
  color: c("c.s90");
  font-size: 2.2rem;
  font-weight: 500;
  flex: 1 1 auto;
}

.ConversationsDetails-objectStatus {
  color: c("c.white");
  min-height: 3.6rem;
  padding: 0.3rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConversationsDetails-objectStatus--available {
  background-color: c("c.positive");
}

.ConversationsDetails-objectStatus--unavailable {
  background-color: c("c.danger");
}

.ConversationsDetails-objectPhoto {
  background-color: c("c.g50");
  margin-top: 2rem;
  margin-bottom: 3rem;

  img {
    background-color: c("c.g50");
    max-width: 100%;
    display: block;
    aspect-ratio: 248 / 180;
  }
}

.ConversationsDetails-notification {
  display: flex;
}

.ConversationsDetails-notification + .ConversationsDetails-notification {
  margin-top: 1.5rem;
}

.ConversationsDetails-notificationIcon {
  color: c("c.s90");
  width: 1.8rem;
  margin-right: 1.6rem;
  flex: 0 0 auto;
}

.ConversationsDetails-notificationText {
  color: c("c.g70");
  padding: 0.4rem 0;
  font-size: 1.5rem;
  line-height: 1.2;
}

.ConversationsDetails-notification {
  &.is-important {
    .ConversationsDetails-notificationIcon {
      color: c("c.danger");
    }
  }
}

@media screen and (min-width: c("bp.m")) {
  .ConversationsDetails {
    top: 10.2rem;
  }
}

@media screen and (min-width: c("bp.l")) {
  .ConversationsDetails {
    width: 30rem;
    // padding: 2.8rem 3rem;
    position: static;
    box-shadow: none;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .ConversationsDetails-notification + .ConversationsDetails-notification {
    margin-top: 2rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .ConversationsDetails {
    width: 34.5rem;
    // padding: 3.6rem 4rem;
  }

  .ConversationsDetails-heading {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }

  .ConversationsDetails-name {
    font-size: 2.2rem;
  }

  .ConversationsDetails-actions {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .ConversationsDetails-action {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .ConversationsDetails-objectValue {
    font-size: 2.6rem;
  }

  .ConversationsDetails-objectPhoto {
    margin-bottom: 4rem;
  }

  .ConversationsDetails-notification + .ConversationsDetails-notification {
    margin-top: 2.5rem;
  }
}
.EachInfoArea {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .InfoTitle {
    font-weight: 700;
    font-size: 14px;
    color: #808081;
    line-height: 17px;
  }
  .InfoValue {
    width: 160px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}
