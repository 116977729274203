@import "../../../../../ui/settings";

.SettingsSelector-Container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .SettingsSelector-Title {
    font-size: 1.7vh;
    display: flex;
    justify-content: center;
  }

  .SettingsSelector-BlockTitle {
    font-size: 1.7vh;
    border-bottom: 2px solid #deeaed;
    color: grey;
  }

  .SettingsSelector-Settings-Container {
    margin: 3vh 0;
    display: flex;
    flex-direction: column;

    .SettingsSelector-Sequences {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      transition: top 1s ease-in;

      .SequenceCard {
        margin: 1vh;
        padding: 1vh;
        padding: 2rem;
        border-radius: 0.8rem;
        min-width: 10vw;
        box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
        font-size: 1vh;

        .SequenceCard-input {
          width: 6vw;
        }
      }
    }
  }
}

@media screen and (max-width: c("bp.s")) {
  .SettingsSelector-Container {
    min-width: 80vw;
    max-width: 80vw;
  }

  .SettingsSelector-BlockTitle {
    min-width: 80vw;
    max-width: 80vw;
  }

  .SettingsSelector-Sequences {
    display: flex;
    flex-direction: column;

    .SequenceCard {
      width: 70vw;
    }
  }

  .SequenceCard-input {
    min-width: 50vw;
  }
}

@media screen and (min-width: c("bp.s")) {
  .SettingsSelector-Container {
    max-width: 50vw;
    min-width: 50vw;
  }

  .SettingsSelector-BlockTitle {
    min-width: 50vw;
    max-width: 50vw;
  }

  .SettingsSelector-Sequences {
    display: flex;
    flex-direction: row;

    .SequenceCard {
      min-width: 10vw;
    }
  }
}
