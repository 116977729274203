@import "../../ui/settings";

.WidgetAccount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 10vw;
}

.WidgetAccount-text {
  @include accent;
  color: c("c.g50");
}

.WidgetAccount-highlight {
  color: c("c.p90");
}

.WidgetAccount-icon {
  color: c("c.shadeC");
  margin-left: 1.8rem;
  flex: 0 0 auto;
}
