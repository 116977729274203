@import "../../../ui/settings";

.AppoitmentForm_layout {
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 10px;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 2.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .Appointment_form {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-top: 20px;
  }

  .CloseIconArea {
    position: relative;
    margin-top: 10px;
    .CloseIcon {
      position: absolute;
      bottom: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      right: 0;
      background-color: green;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .AppointmentTitle {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  .AppointmentDescription {
    color: #666;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
  }
}
