@import "../../../ui/settings";

.SettingsTime--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SettingsTime-ResourceName {
  margin: 2vh 0;
  width: 30%;
}

.SettingsTime-timezone {
  background-color: c("c.white");
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
}

.SettingsTime-groups {
  margin-top: 3rem;
}

.SettingsTime-save {
  margin: 2vh 0;
}

.SettingsTime-groupsLabel {
  @include accent;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: (20 / 18);
}

.SettingsTime-desc {
  width: 50%;
  font-size: 1.4vh;
  font-weight: 300;
  margin-bottom: 2rem;
  line-height: (20 / 18);
}

.SettingsTime-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
}

.SettingsTime-group + .SettingsTime-group {
  margin-top: 3rem;
}

.SettingsTime-actions {
  padding-top: 1.5rem;
  grid-area: 1 / 2 / 2 / 3;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  gap: 1.5rem;
}

.SettingsTime-action--placeholder {
  width: 3.2rem;
  height: 3.2rem;
}

.SettingsTime-action--delete {
  background-color: c("c.g10");
  color: c("c.g30");
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:focus,
  &:hover {
    background-color: c("c.s75");
    color: c("c.white");
    outline: none;
  }
}

.SettingsTime-addDate {
  width: 100%;
  margin-top: 3rem;
  border: 1px dashed c("c.shadeF");
  border-radius: 0.8rem;
}

.SettingsTime-addDateButton {
  background-color: c("c.t");
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;

    .SettingsTime-addDateIcon {
      color: c("c.s90");
    }
  }
}

.SettingsTime-addDateIcon {
  color: c("c.g10");
  transition: color 0.2s;
}

.SettingsTime-addDateLabel {
  @include accent;
  font-weight: 400;
}

.SettingsTime-addDateIcon + .SettingsTime-addDateLabel {
  margin-left: 1.5rem;
}

.SpecificDate-error {
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 2vh;
}

@media screen and (min-width: c("bp.m")) {
  .SettingsTime-timezone {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .SettingsTime-group {
    grid-template-columns: repeat(3, 1fr) auto;
  }

  .SettingsTime-group + .SettingsTime-group {
    margin-top: 2rem;
  }

  .SettingsTime-actions {
    grid-area: auto;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .SettingsTime-groups {
    margin-top: 4.5rem;
  }

  .SettingsTime-addDate {
    margin-top: 4rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsTime-timezone {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .SettingsTime-groups {
    margin-top: 5.5rem;
  }

  .SettingsTime-groupsLabel {
    margin-bottom: 3rem;
  }

  .SettingsTime-group + .SettingsTime-group {
    margin-top: 3rem;
  }

  .SettingsTime-addDate {
    height: 8rem;
  }
}
