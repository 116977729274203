@import "../../ui/settings";

.Settings {
  height: 100%;
  overflow-y: auto;
}

.Settings-blocks {
  max-width: 108.6rem;
  margin-right: auto;
  margin-left: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Settings-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.Settings-blockHeader {
  margin-bottom: 2.5rem;
  border-bottom: 1px solid c("c.shadeB");
}

.Settings-blockTitle {
  @include heading-secondary;
  margin-bottom: 1rem;

  span {
    color: c("c.g25");
  }
}

.Settings-blockDescription {
  @include l_smallest(c("c.g70"));
  margin-bottom: 2.5rem;
  width: 70%;
}

.Settings-topDescription {
  @include l_smallest(c("c.g70"));
  margin-bottom: 0.7rem;
  width: 70%;
}

@media screen and (min-width: c("bp.s")) {
  .Settings-blocks {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .Settings-block {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .Settings-blocks {
    padding-bottom: 7rem;
  }

  .Settings-block {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .Settings-blockHeader {
    margin-bottom: 3.5rem;
  }
}
