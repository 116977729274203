@import "../../../../ui/settings";

.SplittedSendButtonArea {
  display: flex;
  flex-direction: row;
  gap: 1px;
  justify-content: space-between;
  border-radius: 7px;
  width: 165.023px;
  height: 40px;
  margin-left: 1.5rem;
  @media screen and (max-width: c("bp.s")) {
    margin-left: 0;
  }

  .SendButton {
    background-color: c("c.s90");
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    text-transform: c("cdk.generalTextTransform");
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    outline: none;
    border: none;
    flex: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    &:focus,
    &:hover {
      outline: none;
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-button {
    background-color: c("c.s90");
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    height: 100%;
  }

  .dropdown-content {
    display: block;
    position: absolute;
    background-color: white;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    z-index: 1000;
    top: auto;
    bottom: 100%;
    width: 240px;
    right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 30px;

    .DropdownItem {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      gap: 13px;
      cursor: pointer;
      justify-items: center;
      padding-top: 5px;
      padding-bottom: 5px;
      .DropdownItemText {
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 3px;
      }
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f9f9f9;
  }

  .show {
    display: block;
  }
}
