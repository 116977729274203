@import "../../../../ui/settings";

/*  */
.AISale{
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: c("cdk.cardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  border-radius: c("cdk.commonBorderRadius");
  opacity: 1;
  padding: 30px 0;
  margin-bottom: 20px;

  .error {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error p{
    padding: 15px 0;
    letter-spacing: 0.16px;
    font-size: 14px;
    color: #5A5A5A;
  }

  .cardValue{
    display: flex;
    flex-direction: column;
  }

  .cardValue .cardValueWithText{
    padding-left: 0;
  }
}
/*  */

.cardContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: c("cdk.cardBoxShadow");
  border: c("cdk.borderForBoxShadow");
  border-radius: c("cdk.commonBorderRadius");
  opacity: 1;
  padding: 30px 0 20px 0;
  margin-bottom: 20px;

  .error {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error p{
    padding: 15px 0;
    letter-spacing: 0.16px;
    font-size: 14px;
    color: #5A5A5A;
  }

  .cardValue{
    display: flex;
    align-items: center;
  }

  .cardValue .cardValueWithText{
    padding-left: 30px;
  }
}

.redirectLink{
  color: #8777C6 !important;
  cursor: pointer;
}

.cardheader{
  padding-left: 30px;
}

.cardLabel{
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: c("cdk.cardLabelTextColor");
  font-weight: bold;
  text-transform: c("cdk.cardLabelTextTransform");
}

.cardLabel a{
  margin-left: 10px;
}

.cardValue p:first-child{
  font-size: 40px;
  letter-spacing: 0px;
  padding: 10px 0 5px 0;
  font-weight: bold;
}

.cardValue .cardValueWithPercent{
  padding-left: 30px;
}

.cardValue .cardValueWithPercent p{
  display: flex;
  align-items: center;
  font-size: c("cdk.ordinaryFontSize");
  letter-spacing: 0.12px;
  color: #808080;
  font-weight: c("cdk.smallTitleFontWeight");
}

.cardValue .cardValueWithPercent p span{
  color: #000;
}

.cardValue p:last-child span{
  font-size: 22px;
  padding: 0 15px;
}

.cardValue .cardValueWithText div {
  display: flex;
  align-items: center;
}

.cardValue .cardValueWithText p{
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #5A5A5A;
  font-weight: c("cdk.smallTitleDashboardFontWeight");
  padding: 5px 0;
}

.cardValue .cardValueWithText p span{
  font-size: 16px;
  letter-spacing: 0.18px;
  font-weight: bold;
}

.cardError{
  display: flex;
  justify-content: center;
}

@media screen and (min-width: c("bp.m")) {
  .AISale{
    width: 48%;
  }

  .cardContainer{
    width: 48%;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .AISale{
    width: 48%;
  }

  .cardContainer{
    width: 49%;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .AISale{
    width: 48%;
  } 
  
  .cardContainer{
    width: 49%;
  }
}
