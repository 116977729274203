@import "../../../ui/settings";

.CampaignFlowRoot {
  max-height: 100%;
  overflow-y: auto;
}

.CampaignsFlow-Container {
}

.CampaignsFlow-Content-Wrapper {
  height: 100%;
}

.CampaignsFlow-Actions {
  display: flex;
  justify-content: center;
  margin: 5vh 0;
}

.CampaignFlowStepper {
  position: sticky;
  top: 0;
  z-index: 5;
}

.CampaignsFlow-Error {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: c("bp.s")) {
}

@media screen and (min-width: c("bp.s")) {
}
