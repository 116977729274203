.OpCodeComponent-Container {
  background-color: #fff;
  padding: 10vh 10vh 3vh 10vh;
  border-radius: 1vh;
  overflow-x: auto;
  overflow-y: auto;
  min-height: 90vh;
  max-height: 90vh;
  min-width: 80vw;
  max-width: 80vw;
}

.OpCodeComponent-Controlls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OpCodeComponent-Actions {
  display: flex;
  justify-content: flex-end;
  margin: 5vh 0 0 0;
}

.OpCodeComponent-Header {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 5vh 0;

  p {
    font-size: 2vh;
  }
}
.OpCodeComponent-opCodes {
  padding: 3vh;
  margin: 2vh 0;
  max-height: 50vh;
  overflow-y: auto;
  // border: 0.1vh solid grey;
  box-shadow: 0 2rem 5rem 0 rgba(black, 0.1);
  border-radius: 1vh;
}

.OpCode-Main {
  margin: 1vh 0;
  padding: 1vh;
  border-radius: 1vh;
  box-shadow: 0 2rem 10rem 0 rgba(black, 0.15);
  transition: 0.1s ease-in-out;

  &:hover {
    box-shadow: 0 2rem 10rem 0 rgba(black, 0.2);
    transition: 0.2s ease-in-out;
  }

  .OpCode-Main-code {
    font-weight: bold;
    margin: 1vh 0;
  }

  .OpCode-Main-description {
    margin: 1vh 1vw;
  }
}

.OpCode-Main--active {
  background-color: #1f67cc;
  box-shadow: 0 2rem 5rem 0 rgba(#1f67cc, 0.15);
  color: #fff;
}
