@import "../../../ui/settings";

.Settings-TopDescription-bold {
    font-weight: bold;
    color: #B6475A;
    font-size: c("cdk.settingsSubHeaderBiggestFontSize");
}

.Settings-TopDescription-ul {
    color: #B6475A;
    font-size: c("cdk.settingsSubHeaderBiggestFontSize");
    padding-left: 2rem;
    list-style-position: outside;
    list-style-type: decimal;
    font-weight: normal;
}

.Settings-TopDescription-ul li {
    margin-top: 0.7rem;
}

.Settings-TopDescription-nested-ul {
    padding-left: 2rem;
    list-style-position: outside;
    list-style-type: lower-alpha;
    font-weight: normal;   
}

.Settings-TopDescription-note {
    background-color: #B6475A1A;
    border-radius: 10px;
    font-size: c("cdk.settingsSubHeaderBiggestFontSize");
    padding: 5px;
    display: flex;
    align-items: center;
    margin: 15px auto;
}

.Settings-TopDescription-noteTitle {
    background-color: #B6475A;
    border-radius: 10px;
    margin-right: 5px;
    padding: 3px 10px 3px 10px;
    color: white;
    display: flex;
    align-items: center;
}