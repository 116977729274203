@import "../../../ui/settings";

.TableRow {
  &:hover {
    td {
      background-color: c("c.shadeD");
    }
  }
}

.TableRow--even {
  td {
    background-color: c("cdk.conversationsTableEvenRowsBackgroundColor");
  }
}

.TableRow--interactive {
  cursor: pointer;
}

.TableRow--sub-source {
  td {
    background-color: #F2F2F2;
    fill: #DBDBDB;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05) inset;
    color: #4D4D4D;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
