@import "../../../ui/settings";

.FormSwitch {
  display: flex;
  align-items: center;

  .FormSwitch-ToggleName {
    color: #808080;
    font-weight: 700;
    text-transform: c("cdk.generalTextTransform");
    display: block;
  }
}
