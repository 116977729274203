@import "../../ui/settings";

.Conversations {
  height: 100%;

  display: grid;
  grid-template-rows: auto min-content;
  grid-template-columns: 100%;
}
.Loader-area {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Conversations-table {
  overflow: auto;
}

.Conversations-contacts {
  display: grid;
  grid-template-columns: max-content auto;

  a {
    color: c("c.s90");
  }
}

.Conversations-contactLabel {
  color: c("c.g30");
  padding-right: 1rem;
}

.Conversations-summary {
  background-color: c("c.white");
  border-top: 1px solid c("c.shadeA");
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.Conversations-info {
  padding: 1rem;
  font-size: c("cdk.settingsSubHeaderFontSize");
  font-weight: c("cdk.smallTitleFontWeight");
  text-align: center;
  text-transform: c("cdk.cardLabelTextTransform");
  letter-spacing: 0.05em;
  flex: 0 0 100%;
}

.Conversations-infoLabel {
  color: c("cdk.smallTitleTextColor");
}

.Conversations-pagination {
  padding: 1rem;
  flex: 0 0 auto;
}

@media screen and (min-width: c("bp.s")) {
  .Conversations-info {
    text-align: left;
    flex-grow: 1;
    flex-basis: auto;
  }
}

@media screen and (min-width: c("bp.m")) {
  .Conversations-summary {
    min-height: 7rem;
  }

  .Conversations-info {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .Conversations-pagination {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
