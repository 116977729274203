@import "../../../ui/settings";

.SettingsTimePicker {
  width: 100%;

  &.SettingsTimePicker--iconed {
    .SettingsTimePicker-field {
      padding-left: 3rem;
    }
  }

  &.SettingsTimePicker--error {
    .SettingsTimePicker-field {
      border-color: c("c.danger");
    }
  }
}

.SettingsTimePicker-label:not(.SettingsTimePicker-label--hidden) {
  @include smallest(c("cdk.smallTitle"));
  margin-bottom: 0.4rem;
  display: block;
}

.SettingsTimePicker-label--hidden {
  @include sr-only;
}

.SettingsTimePicker-field {
  border-bottom: 2px solid c("c.shadeB");
  display: flex;
  position: relative;
  transition: border-color 0.3s;
}

.FormSelect-fieldIcon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.SettingsTimePicker-input {
  background-color: c("c.t");
  width: 2.75em;
  min-height: 3.8rem;
  font-size: 1.6rem;
  padding: 0.5rem 0;
  border: none;
  appearance: none;

  &:focus {
    outline: none;
  }

  &[readonly] {
    color: rgba(c("c.p90"), 0.25);
  }

  &:not([readonly]) {
    &:focus,
    &:hover {
      border-color: c("c.shadeC");
    }
  }
}

.SettingsTimePicker-daytimeToggler {
  background-color: c("c.t");
  margin: 0;
  padding: 0 0.3rem;
  border: none;
  cursor: pointer;
  transition: color 0.3s;

  &[disabled] {
    color: rgba(c("c.p90"), 0.25);
    cursor: not-allowed;
  }

  &:focus,
  &:hover {
    color: c("c.s90");
    outline: none;
  }
}

@media screen and (min-width: c("bp.m")) {
  .SettingsTimePicker-input {
    min-height: 4.4rem;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .SettingsTimePicker-input {
    font-size: 1.8rem;
    min-height: 5.2rem;
  }

  .SettingsTimePicker-daytimeToggler {
    font-size: 1.8rem;
  }
}
