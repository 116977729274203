@import "../../ui/settings";

.Layout {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 7rem;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.Layout-logo {
  background-color: c("c.navBackground");
  width: 7.8rem;
  height: 7rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  a {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  img {
    display: block;
    width: c("cdk.logoSize");
    height: c("cdk.logoSize");
  }
}

.Layout-navigation {
  background-color: c("c.navBackground");
  border-right: c("cdk.navDividerWidth") solid c("cdk.dividerColor");
  height: 7rem;
  position: fixed;
  top: 0;
  left: 7.8rem;
  right: 0;
  z-index: 10;
}

.Layout-widgets {
  background-color: c("c.g01");
  border-bottom: 1px solid c("cdk.dividerColor");
  display: flex;
  align-items: center;
}

.Layout-content {
  flex: 1 0 auto;
  overflow: hidden;
}

@media screen and (min-width: c("bp.m")) {
  .Layout {
    padding-top: 0;
    overflow-y: initial;

    display: grid;
    grid-template-rows: 2.5rem minmax(10rem, max-content);
    grid-template-columns: minmax(8rem, 7.5%);
  }

  .Layout_no-header {
    grid-template-rows: 2.5rem 10rem;
  }

  .Layout_noAppSwitch {
    grid-template-rows: 0 minmax(10rem, max-content);
  }

  .Layout-appSwitch {
    grid-area: 1 / 1 / 1 / 3;
  }

  .Layout-logo {
    background-color: c("c.logoBackground");
    border-bottom: c("cdk.navDividerWidth") solid c("cdk.dividerColor");
    width: auto;
    height: auto;
    grid-area: 2 / 1 / 2 / 2;
    position: static;
  }

  .Layout-navigation {
    height: auto;
    grid-area: 3 / 1 / 3 / 2;
    position: static;
  }

  .Layout-widgets {
    grid-area: 2 / 2 / 2 / 3;
  }

  .Layout-content {
    grid-area: 3 / 2 / 3 / 3;
  }

  .Layout-content_no-header {
    grid-area: 2 / 2 / 4 / 3;
  }
}

@media screen and (min-width: c("bp.l")) {
  .Layout {
    grid-template-rows: 2.5rem 10rem;
  }
  .Layout_noAppSwitch {
    grid-template-rows: 0 minmax(10rem, max-content);
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .Layout {
    grid-template-rows: 2.5rem 13.8rem;
  }
  .Layout_noAppSwitch {
    grid-template-rows: 0 minmax(10rem, max-content);
  }
}

@media screen and (min-width: c("bp.3xl")) {
  .Layout {
    grid-template-columns: 14.4rem;
  }
}
