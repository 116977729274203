@import "../../../ui/settings";

.cm-e-menu {
  width: 100%;
	position: relative;
	z-index: 1000;
	}

.cm-e-menu li {
	position: relative;
	white-space: nowrap;
	cursor: pointer;
	list-style: none;
  width: 100%;
	-webkit-transition: background-color ease-in 300ms;
	-moz-transition: background-color ease-in 300ms;
	-ms-transition: background-color ease-in 300ms;
	-o-transition: background-color ease-in 300ms;
	transition: background-color ease-in 300ms;
}

.cm-e-menu ul li.topmenu {
	display: inline-table;
}
.cm-e-menu .topmenu ul {
	display: none;
	position: absolute;
	background-color: c("c.white");
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 5px 5px 0 rgba(c("c.black"), 0.1);
}
.cm-e-menu li:hover > ul {
	display: table;
  z-index: 999;
}
.cm-e-menu ul.submenu {
	top: 100%; 
  left: 0;
	padding-top: .5em;
	padding-bottom: .5em;	
}
.cm-e-menu ul.submenu .submenu {
	top: 0; 
  left: 100%;
  box-shadow: 5px 5px 5px 5px rgba(c("c.black"), 0.1);
  border-radius: 0.5rem 0.5rem 0.8rem 0.8rem;
  width: 70%;
}


.MDropDown-label:not(.MDropDown-label--hidden) {
  @include smallest(c("cdk.smallTitleTextColor"));
  margin-bottom: 0.4rem;
  display: block;
}

.MDropDown-label--hidden {
  @include sr-only;
}

.MDropDown-field {
  position: relative;
}

.MDropDown-fieldIcon {
  color: c("c.shadeC");
  position: absolute;
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.MDropDown-toggler {
  @include input;
  text-align: left;
  cursor: pointer;
  width: 75%;
  display: flex;
  align-items: center;

  &:focus,
  &:hover {
    outline: none;
  }
}

.MDropDown-togglerText {
  max-width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 auto;
  overflow: hidden;
}

.MDropDown-togglerIcon{
  color: black;
}

.MDropDown-list {
  background-color: c("c.white");
  width: 100%;
  max-height: 30rem;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 2rem 5rem 0 rgba(c("c.black"), 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  overflow-y: auto;
}

.MDropDown:not(.MDropDown--open) .MDropDown-list {
  display: none;
}

.MDropDown-listItem {
  list-style: none;
  min-height: 4.5rem;
  padding: 0.5rem 3rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: c("cdk.subHeaderTextTransform");
  letter-spacing: 0.05em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.MDropDown-listItems--active {
    color: c("c.s90");
    text-decoration: underline;
  }

  &:not(.MDropDown-listItems--active){
    color: black;
  }

  &.MDropDown-listItems--disabled {
    color: c("c.g20");
    cursor: not-allowed;
  }

  &:hover{
    text-decoration: underline;
  }
}

.MDropDown-listItem + .MDropDown-listItem {
  border-top: 1px solid c("c.shadeA");
}

.MDropDown-SecondaryList{
  background-color: c("c.white");
  width: 100%;
  max-height: 30rem;
  padding: 0.5rem 0;
  border-radius: 0 0 0.8rem 0.8rem;
  position: absolute;
  top: 0;
  left: 100%;
}

@media screen and (min-width: c("bp.m")) {
  .MDropDown-toggler {
    width: 50%;
  }
}

@media screen and (min-width: c("bp.l")) {
  .MDropDown-toggler {
    width: 60%;
  }
}

@media screen and (min-width: c("bp.xl")) {
  .MDropDown-toggler {
    width: 70%;
  }
}

@media screen and (min-width: c("bp.xxl")) {
  .MDropDown-toggler {
    width: 80%;
  }
}
