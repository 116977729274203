@import "./ui/fonts.scss";

@import-normalize;
@import "./ui/settings";

html {
  min-height: 100%;
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: c("c.white");
  font-family: c("cdk.mainFontFamily");
  font-size: 1.6rem;
  overflow: hidden;
}

body,
form,
fieldset,
legend,
table,
th,
td,
caption,
hr,
blockquote,
dl,
dd,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
  padding: 0;
}

input,
select,
optgroup,
button,
textarea {
  font-family: inherit;
  font-size: 100%;
}

svg {
  fill: currentColor;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}
